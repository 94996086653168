import { Card, Col, Stack } from 'react-bootstrap'
import * as constants from '../constants'
import playIcon from '../assets/landing/playIcon.png'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { AppContext } from '../context'

const AdminStaffPickVideoCard = (props) => {
  const { openVideoModal } = useContext(AppContext)

  return (
    <Col key={props.id} xl={3} md={4} sm={12} className="mb-4">

      <div className='d-flex w-100 flex-column'>

        <Card className='video-card crop h-100'>
          <div className='d-flex w-100 justify-content-center text-center align-items-center' style={{ position: 'absolute', zIndex: 100, bottom: 0, backgroundColor: 'rgba(52, 52, 52, 0.6)', width: '100%', padding: 5, borderRadius: '0 0 10px 10px' }}>
            <p className='pb-2' onClick={() => { props.removeStaffPick(props.id, false) }} style={{ textAlign: 'center', color: 'lightgrey', fontSize: 16, marginTop: 15, cursor: 'pointer' }}>Remove from <span style={{ fontWeight: 'bold' }}>Staff Pick</span>
              <FontAwesomeIcon
                icon={faTrashCan}
                size="lg"
                className="me-2"
                style={{ paddingLeft: 10 }}
              />
            </p>
          </div>

          <Card.Img src={`${props.image.indexOf('http://') === 0 || props.image.indexOf('https://') === 0 ? '' : constants.URL}${props.image}`} alt="Card image" />
          <Card.ImgOverlay className="text-white h-100 d-flex flex-column justify-content-end">
            <div className='Card_Info'>
              {props.staffPick &&
                <div className="Card_Info_Item">
                  <p>Staff pick</p>
                </div>
              }
              {props.recommended &&
                <div className="Card_Info_Item">
                  <p>Recommended</p>
                </div>
              }
            </div>
            <img src={playIcon} className="play-icon cursor-pointer" alt="" onClick={() => openVideoModal({ ...props.video, isAdmin: true })} />
            <div>
              <Stack direction="horizontal" gap={4} className="justify-content-center">
                <div>
                </div>
              </Stack>
            </div>
          </Card.ImgOverlay>
        </Card>
        <div className="mt-2">
          <p className='Card__Name'>{props.videoName}</p>
          <p className='Card__UserName'>
            {props.video.user_name} {props.video.user_last_name}
          </p>
        </div>

      </div>
    </Col>
  )
}

export default AdminStaffPickVideoCard

AdminStaffPickVideoCard.propTypes = {
  video: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  socialNet: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired,
  videoName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string,
  country: PropTypes.string,
  city: PropTypes.string,
  age: PropTypes.string,
  mobile: PropTypes.string,
  image: PropTypes.string,
  staffPick: PropTypes.bool,
  recommended: PropTypes.bool,
  description: PropTypes.string.isRequired,
  videoSrc: PropTypes.string.isRequired,
  voted: PropTypes.bool,
  show: PropTypes.bool,
  pendingVideo: PropTypes.func,
  removeStaffPick: PropTypes.func,
  isAdmin: PropTypes.bool,
}
