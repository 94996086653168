import { useContext, useEffect } from 'react'
import { useLocation, Outlet, useNavigate } from 'react-router'
import { AppContext } from '../context'
import Header from '../containers/Header'

const AuthLayout = () => {
  const { token, firstCharge } = useContext(AppContext)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!firstCharge && token) {
      navigate('/')
    }
  }, [token, firstCharge])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <>
      <Header isAuth />
      <Outlet />
    </>
  )
}

export default AuthLayout