import { useContext, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import { AppContext } from '../context'

const CompetitionsDropdown = (props) => {
  const {
    timelineCompetitions,
  } = useContext(AppContext)
  let [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line no-unused-vars
  const [selectedCompetitionName, setSelectedCompetitionName] = useState('Competition Year')

  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  const competition_id = Number(searchParams.get('competition_id'))

  useEffect(() => {
    if (competition_id && timelineCompetitions) {
      let selectedCompetition = null
      
      if (timelineCompetitions.currentCompetition?.id === competition_id) {
        selectedCompetition = timelineCompetitions.currentCompetition
      } else if (timelineCompetitions.lastCompetitions?.length) {
        selectedCompetition = timelineCompetitions.lastCompetitions.find(competition => competition.id === competition_id)
      }

      if (selectedCompetition) {
        setSelectedCompetitionName(selectedCompetition.name)
      }
    }
  }, [competition_id, timelineCompetitions])

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleCompetitionClick = (competition_id) => {
    setSearchParams((prev) => {
      const newParams = {}
      if (competition_id)
        newParams.competition_id = competition_id
      if (prev.get('type'))
        newParams.type = prev.get('type')
      return newParams
    })
    setIsOpen(false)
  }

  const closeDropdownOnOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', closeDropdownOnOutsideClick)
    return () => {
      document.removeEventListener('click', closeDropdownOnOutsideClick)
    }
  }, [])

  return (
    <>
      <div ref={dropdownRef} className={`Compe-Drop ${isOpen ? 'show' : ''} ${props.className ?? ''}  `}>
        <button className="nav-link Compe-buttonDrop" onClick={toggleDropdown} aria-expanded={isOpen} style={{minWidth: '200px'}}>
          {selectedCompetitionName}
          <FontAwesomeIcon
            icon={faCaretDown}
          />
        </button>
        <ul className={`Compe-Menu pt-2 ${isOpen ? 'show' : ''}`}>
          {timelineCompetitions.currentCompetition && (
            <>
              <li className='Nav-Menu__section'>Current competition</li>
              <li className="CompetitionLink" onClick={() => handleCompetitionClick(timelineCompetitions.currentCompetition.id)}>
                {timelineCompetitions.currentCompetition.name}
              </li>
              <li className='Nav-Menu__section'>Past competitions</li>
              {timelineCompetitions.lastCompetitions && timelineCompetitions.lastCompetitions.length > 0 && (
                timelineCompetitions.lastCompetitions.map((lastCompetition) => (
                  <li key={lastCompetition.id} className='CompetitionLink pb-2' onClick={() => handleCompetitionClick(lastCompetition.id)}>
                    {lastCompetition.name}
                  </li>
                ))
              )}
            </>
          )}

          {!timelineCompetitions.currentCompetition && (
            <>
              <li className='Nav-Menu__section'>Past competitions</li>
              {timelineCompetitions.lastCompetitions && timelineCompetitions.lastCompetitions.length > 0 && (
                timelineCompetitions.lastCompetitions.map((lastCompetition) => (
                  <li key={lastCompetition.id} className='CompetitionLink pb-2' onClick={() => handleCompetitionClick(lastCompetition.id)}>
                    {lastCompetition.name}
                  </li>
                ))
              )}
            </>
          )}
        </ul>
      </div>
    </>
  )
}

export default CompetitionsDropdown

CompetitionsDropdown.propTypes = {
  className: PropTypes.string
}