export const ideasList = [
  'Capture a day in the life of a local farmer or producer',
  'Showcase a hidden gem or secret spot in your area',
  'Film a unique local festival or event',
  'Highlight a local artisan or craftsperson at work',
  'Create a timelapse of a stunning Queensland sunset or sunrise',
  'Document a traditional Indigenous story or cultural practice',
  'Showcase your favourite local café or restaurant',
  'Film the transformation of your town during different seasons',
  'Capture the beauty of local wildlife in their natural habitat',
  'Create a video tour of a historical landmark in your region',
  'Showcase a day of adventure at a nearby national park',
  'Highlight a local initiative making a difference in your community',
  'Film a "field to plate" journey of a local ingredient',
  'Capture the excitement of a regional rodeo or country show',
  'Create a video about your favourite local fishing spot',
  'Showcase the unique architecture or street art in your town',
  'Film a day trip to a nearby attraction or natural wonder',
  'Highlight a local legend or character who embodies the Queensland spirit',
  'Create a video about how your community comes together during tough times',
  'Showcase the night sky and stargazing opportunities in your area',
  'Explore a local farmers\' market and its unique products',
  'Highlight a day in the life of a rural firefighter or emergency service volunteer',
  'Create a video about a quirky local attraction or roadside oddity',
  'Document the restoration of a heritage building in your area',
  'Capture the energy of a local live music performance or venue',
  'Showcase the diverse landscapes within a short drive of your town',
  'Highlight a local eco-friendly initiative or sustainable practice',
  'Create a video about your favourite local swimming hole or beach',
  'Film a "welcome to my town" tour from a local\'s perspective',
  'Showcase a day in the life of a country vet or wildlife rescuer',
  'Capture the excitement of a local sporting event or rivalry',
  'Create a video about the history and significance of a local Indigenous place name',
  'Highlight the changing colours of the outback throughout the day',
  'Showcase a local adventure tourism activity (e.g., skydiving, river rafting)',
  'Document a community volunteer project in action',
  'Create a video about the unique flora in your region',
  'Highlight a day in the life of a rural teacher or student',
  'Showcase the process of a traditional rural skill or craft (e.g., sheep shearing, blacksmithing)',
  'Create a video about how your community celebrates major holidays in a uniquely Queensland way',
  'Showcase the local birdlife and their unique calls',
  'Create a video about your town\'s annual agricultural show',
  'Highlight a day in the life of a rural postal worker',
  'Showcase your favourite local bakery and its signature treats',
  'Create a video about the history and significance of a local landmark',
  'Highlight the beauty of wildflowers blooming in your region',
  'Document a day in the life of a rural apprentice',
  'Showcase the local rodeo scene and its importance to the community',
  'Create a video about your town\'s connection to a famous person or event',
  'Highlight the process of harvesting a local crop',
  'Document the restoration of a classic Australian car or machinery',
  'Showcase the local pub and its role as a community hub',
  'Create a video about the unique challenges and joys of rural dating',
  'Highlight a day in the life of a rural health worker',
  'Document the process of preparing for and recovering from extreme weather events',
  'Showcase the local Aboriginal art scene and its cultural significance',
  'Create a video about your favourite stargazing spot and its celestial views',
  'Highlight the process of droving cattle or mustering sheep',
  'Highlight the unique wildlife adaptations in your region\'s ecosystem',
  'Create a video about a multi-generational family farm or business',
  'Showcase the local bush poetry or storytelling scene',
  'Document a day in the life of a rural pilot or flying doctor',
  'Showcase the local indigenous bush tucker and its uses',
  'Document the process of restoring a historical homestead or station',
  'Highlight the unique geological features in your area',
  'Create a video about the local water sources and their importance',
  'Showcase the process of creating and maintaining bush fire breaks',
  'Document a day in the life of a rural mechanic or trades person',
  'Highlight the local beekeeping industry and its impact on agriculture',
  'Create a video about the most unusual job in your community',
  'Showcase the process of organising a community event or festival',
  'Document the journey of relocating to rural Queensland from a city',
  'Highlight the local efforts in wildlife conservation or rescue',
  'Create a video showcasing your local community garden and its impact',
  'Highlight a unique hobby like whip cracking or leather crafting',
  'Document a personal achievement, like completing a long-distance charity run',
  'Highlight a successful community fundraising event and its beneficiaries',
  'Create a video about your favourite local fishing spot and your best catch',
  'Highlight a local person who has overcome adversity to achieve their dreams',
  'Create a video about your town\'s signature dish',
  'Showcase a day in the life of a passionate birdwatcher in your area',
  'Document a successful land regeneration or conservation project',
  'Highlight your community\'s efforts in supporting local youth initiatives',
  'Create a video about your favourite local music band or performer',
  'Showcase a community art project that beautifies your town',
  'Highlight what you love most about your town\'s main street or gathering place',
  'Create a video showcasing your favourite locations around Queensland',
  'Highlight how your community has changed over the years',
  'Provide a history of our town or station',
  'Detail what it\'s like to live and work out on a farm',
  'Capture the stark beauty of sun-bleached cattle stations in the outback',
  'Record a poem about why you love living in Queensland',
  'Perform a song of why you love living in Queensland',
  'Record and interview a local celebrity',
  'Showcase your region\'s story through the "eyes" of a flying drone',
  'Showcase the "music" created by natural elements in your area',
  'Document your community\'s story through the eyes of its oldest resident',
  'Create a video of a long road-trip',
  'Showcase your favourite camping spot and why you love to stay there',
  'Highlight your appreciation for the natural environment of your home town',
]
