import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMagnifyingGlass,
  faFileExport,
  faAngleDown,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons'
import {
  Table,
  Container,
  InputGroup,
  Form,
  Row,
  Col,
  Spinner,
  Pagination,
} from 'react-bootstrap'
import { useQuery } from 'react-query'
import { getUsers } from '../../api/users'
import { useContext, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { AppContext } from '../../context'
import UserTableRow from '../../components/UserTableRow'
import UserTableMobile from '../../components/UserTableMobile'

function AdminUsers() {
  const { token, user } = useContext(AppContext)
  const [allUsers, setAllUsers] = useState(false)
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const csvInstance = useRef()
  const [orderBy, setOrderBy] = useState('')
  const body = {
    page,
    search: searchTerm,
    order_by: orderBy,
  }
  const { isFetching, data } = useQuery(
    ['users', page, searchTerm, orderBy],
    () => getUsers(token, body),
    {
      initialData: { total_pages: 1, page: 1, total_items: 0, data: [] },
      enabled: token !== '' && (user?.user_serializer.is_superuser ?? false),
      keepPreviousData: true,
    }
  )
  const [sort, setSort] = useState({
    value: orderBy,
    bool: true,
  })

  useEffect(() => {
    setOrderBy(
      sort.value === ''
        ? ''
        : sort.bool === true
          ? `${sort.value}`
          : `-${sort.value}`
    )
  }, [sort])

  useEffect(() => {
    setPage(Number(data.page))
  }, [data])

  useEffect(() => {
    if (allUsers && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click()
        setAllUsers(false)
      })
    }
  }, [allUsers])

  // const search = (text) => {
  //   const delayDebounceFn = setTimeout(() => {
  //     if (searchTerm === text) return
  //     setSearchTerm(text)
  //     let body2 = text !== '' ? {search: text} : {}
  //     getUsers(token, body2).then((users) =>{
  //       setUsers(users.data)
  //     })
  //   }, 500)

  //   return () => clearTimeout(delayDebounceFn)
  // }

  const getAllUsers = () => {
    getUsers(token, { per_page: data.total_items }).then((users) => {
      setAllUsers(users.data)
    })
  }

  const handleSort = (value) => {
    setSort((prev) => {
      if (prev.value !== value) 
        return {
          value: value,
          bool: true,
        }

      if (prev.bool)
        return {
          value: value,
          bool: false,
        }
      else
        return {
          value: '',
          bool: true,
        }
    })
  }

  return (
    <Container fluid>
      <Row>
        <Col sm={4}>
          <InputGroup className="mb-3">
            <InputGroup.Text
              className="bg-white border-end-0 border ms-n5"
              id="basic-addon1"
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputGroup.Text>
            <Form.Control
              placeholder="Search"
              aria-label="search"
              aria-describedby="basic-addon1"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="cursor-pointer" onClick={() => getAllUsers()}>
            <FontAwesomeIcon icon={faFileExport} className="me-2" /> Export data
          </div>
          {allUsers ? (
            <CSVLink
              data={allUsers}
              filename={'rs-competition-users.csv'}
              ref={csvInstance}
            />
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <div className="AdminUsers__Table_Mobile">
        {data.data.length !== 0 ? (
          data.data.map((element) => (
            <UserTableMobile key={element.id} video={element} />
          ))
        ) : (
          <></>
        )}
      </div>
      <div className="AdminUsers__table_container">
        <Table striped hover className="AdminUsers__table d-none d-lg-table">
          <thead>
            <tr>
              <th style={{ whiteSpace: 'nowrap' }}>Full Name</th>
              <th>Email</th>
              <th>Age</th>
              <th>Mobile</th>
              <th>Country</th>
              <th>City</th>
              <th
                onClick={() => handleSort('ranking')}
                className="text-decoration-underline cursor-pointer"
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                Ranking nº
                {sort.value === 'ranking' ? (
                  sort.bool ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="Table__Header_Icon text-4 ms-1"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="Table__Header_Icon text-4 ms-1"
                    />
                  )
                ) : (
                  <span style={{ width: '14px', height: '16px', display: 'inline-block', marginLeft: '0.25rem' }}/>
                )}
              </th>
              <th 
                onClick={() => handleSort('votes')}
                className="text-decoration-underline cursor-pointer"
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                Likes nº
                {sort.value === 'votes' ? (
                  sort.bool ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="Table__Header_Icon text-4 ms-1"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="Table__Header_Icon text-4 ms-1"
                    />
                  )
                ) : (
                  <span style={{ width: '14px', height: '16px', display: 'inline-block', marginLeft: '0.25rem' }}/>
                )}
              </th>
              <th>Status</th>
              <th>Video</th>
              <th style={{'minWidth': '50px'}}></th>
            </tr>
          </thead>
          <tbody>
            {data.data.length !== 0 ? (
              data.data.map((element) => (
                <UserTableRow
                  key={element.id}
                  id={element.id}
                  full_name={element.full_name}
                  votes={element.votes}
                  videoSrc={element.url}
                  ranking={element.ranking}
                  slug={element.slug}
                  age={element.age}
                  city={element.city}
                  country={element.country}
                  email={element.email}
                  mobile={element.mobile}
                  status={element.video_status}
                />
              ))
            ) : (
              <tr></tr>
            )}
          </tbody>
        </Table>
      </div>
      {token === '' || isFetching ? (
        <div className="text-center">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <></>
      )}
      <div className="float-end">
        <Pagination size={'sm'}>
          <Pagination.First disabled={page === 1} onClick={() => setPage(1)} />
          <Pagination.Prev
            disabled={page === 1}
            onClick={() => setPage((old) => Math.max(old - 1, 1))}
          />
          <Pagination.Item active>{page}</Pagination.Item>
          <Pagination.Next
            disabled={page === data.total_pages}
            onClick={() => page < data.total_pages && setPage((old) => old + 1)}
          />
          <Pagination.Last
            disabled={page === data.total_pages}
            onClick={() => setPage(data.total_pages)}
          />
        </Pagination>
      </div>
    </Container>
  )
}

export default AdminUsers
