import PropTypes from 'prop-types'

const ContestantsVideo = ({ image, username, title, description }) => {
  return (
    <main className='ContestantsVideo'>
      <div className='ContestantsVideo__Image'>
        <img src={image} alt="video" />
      </div>
      <div className='ContestantsVideo__Content'>
        <p className='ContestantsVideo__Username'>{username}</p>
        <p className='ContestantsVideo__Title'>{title}</p>
        <p className='ContestantsVideo__Description' title={description}>
          {description}
        </p>
      </div>

    </main>
  )
}

ContestantsVideo.propTypes = {
  image: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default ContestantsVideo