import { Helmet } from 'react-helmet'
import FaqsBanner from '../assets/landing/FaqsBanner.webp'
import FaqsBannerMobile from '../assets/landing/FaqsBannerMobile.webp'
import { useMediaQuery } from '@uidotdev/usehooks'
import { Accordion } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const Faqs = () => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/#how-to-enter', { replace: true })
  }

  const handleInspirationClick = () => {
    navigate('/inspiration', { replace: true })
  }

  return (
    <main>
      <Helmet>
        <title>FAQ | RFDS (Queensland Section) + Elders</title>
      </Helmet>
      <section className="Inspiration__Banner">
        <img
          className="Inspiration__Banner__Image"
          src={isMobile ? FaqsBannerMobile : FaqsBanner}
          alt="bannerBackground"
        />
        <p className='Inspiration__Banner__Textfaqs text-center px-3'>Frequently asked questions</p>
      </section>
      <section className="Faqs" >

        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>How do I upload my video?</Accordion.Header>
            <Accordion.Body>
              To submit your video into the competition,{' '}
              <button onClick={handleClick}>
                click here.
              </button>
              <br />
              Please note that for your video to be successfully accepted into the competition it must:
              <ul>
                <li>not be longer than 2 minutes in length</li>
                <li>contain the Outback Explorer Competition video frames</li>
                <li>be uploaded to a publicly accessible YouTube, TikTok, or Instagram account</li>
              </ul>
              If you experience any issues creating or uploading your video, please contact us via email at <a href="mailto:competitions@rfdsqld.com.au">competitions@rfdsqld.com.au</a>.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>What should I do if my video is rejected?</Accordion.Header>
            <Accordion.Body>
              Don&apos;t panic! If your video has been rejected, check the rejection email for an explanation and instructions on how to fix and resubmit your video.
              <br />
              <br />
              If you have any issues creating or uploading your video, please contact us via email at <a href="mailto:competitions@rfdsqld.com.au">competitions@rfdsqld.com.au</a>.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>When will the competition end?</Accordion.Header>
            <Accordion.Body>
              Submissions for the Outback Explorer Competition will close on Tuesday 19 November at 11:59pm.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>When will the winners be announced?</Accordion.Header>
            <Accordion.Body>
              After our judging panel has reviewed all video entries, the winners for all prize categories—including Judge’s Pick, People’s Choice, and Voting Account Lucky Draw—will be announced by Friday 6 December 2024.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>What should my video be about?</Accordion.Header>
            <Accordion.Body>
              We want to see what you love about Queensland! Whether it&apos;s the stunning landscapes, the vibrant people, diverse cultures, or anything else that makes Queensland special to you, share it with us.
              <br />
              <br />
              Need some inspiration? Visit our{' '}
              <button onClick={handleInspirationClick}>
                Inspiration page
              </button>
              {' '}and use the idea generator to spark your creativity and find great ideas for your video submission.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Accordion.Header>Which social media platforms can I post my video from?</Accordion.Header>
            <Accordion.Body>
              As part of the submission process, your video must be posted on YouTube, Instagram, or TikTok.
              <br />
              <br />
              When submitting your video entry form, please provide the embed link from your chosen social media platform.
              <br />
              <br />
              <strong>Where to find the embed link for your video:</strong>
              <br />
              <br />
              <strong>YouTube:</strong>
              <ol>
                <li>Go to your video on YouTube.</li>
                <li>Click on the &quot;Share&quot; button below the video.</li>
                <li>Select the &quot;Embed&quot; option from the sharing menu.</li>
                <li>Copy the embed link provided.</li>
              </ol>
              <strong>TikTok:</strong>
              <ol>
                <li>Open your video on TikTok.</li>
                <li>Tap the &quot;Share&quot; icon (arrow pointing right).</li>
                <li>Select the &quot;Copy Link&quot; option.</li>
                <li>Paste the copied link where required.</li>
              </ol>
              <strong>Instagram:</strong>
              <ol>
                <li>Go to your video post on Instagram.</li>
                <li>Tap the three dots (ellipsis) on the top right corner of the post.</li>
                <li>Select &quot;Embed&quot; from the menu.</li>
                <li>Copy the embed code provided.</li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>My video has been submitted but is not playing on the competition website.</Accordion.Header>
            <Accordion.Body>
              If you have successfully entered your video into the competition but notice it is not playing or shows &quot;video unavailable,&quot; please check the following:
              <br />
              <br />
              <ol>
                <li>Ensure your social media profile is set to public.</li>
                <li>Ensure your video does not use any licensed music.</li>
              </ol>
              If these criteria are met and your video is still not playing, please contact us via email at <a href="mailto:competitions@rfdsqld.com.au">competitions@rfdsqld.com.au</a>.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7">
            <Accordion.Header>Where do I find my embed link for YouTube, Instagram, or TikTok?</Accordion.Header>
            <Accordion.Body>
              To enter the competition, we require you to submit an embed link for your video. Here&apos;s how to find it:
              <br />
              <br />
              <strong>YouTube:</strong>
              <ol>
                <li>Go to your video on YouTube.</li>
                <li>Click on the &quot;Share&quot; button below the video.</li>
                <li>Select the &quot;Embed&quot; option from the sharing menu.</li>
                <li>Copy the embed link provided.</li>
              </ol>
              <strong>TikTok:</strong>
              <ol>
                <li>Open your video on TikTok.</li>
                <li>Tap the &quot;Share&quot; icon (arrow pointing right).</li>
                <li>Select the &quot;Copy Link&quot; option.</li>
                <li>Paste the copied link where required.</li>
              </ol>
              <strong>Instagram:</strong>
              <ol>
                <li>Go to your video post on Instagram.</li>
                <li>Tap the three dots (ellipsis) on the top right corner of the post.</li>
                <li>Select &quot;Embed&quot; from the menu.</li>
                <li>Copy the embed code provided.</li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>
    </main>
  )
}

export default Faqs