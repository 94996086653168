import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsisVertical,
  faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons'
import { Popover, OverlayTrigger, Nav } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { getVideosDetails } from '../api/videos'
import { useContext } from 'react'
import { AppContext } from '../context'

function UserTableRow(props) {
  const { token, openVideoModal } = useContext(AppContext)

  const openModal = (slug) => {
    const getVideoInfo = async () => {
      const response = await getVideosDetails(token, slug)

      if (response.error) console.error(response.error)
      else if (response) {
        openVideoModal({...response, isAdmin: true})
      }
    }
    getVideoInfo()
  }

  return (
    <tr key={props.id}>
      <td>{props.full_name}</td>
      <td>{props.email}</td>
      <td>{props.age}</td>
      <td>{props.mobile}</td>
      <td>{props.country}</td>
      <td>{props.city}</td>
      <td>{props.ranking}</td>
      <td>{props.votes}</td>
      <td>{props.status}</td>
      <td className="text-center">
        <FontAwesomeIcon
          icon={faArrowUpRightFromSquare}
          className="cursor-pointer"
          onClick={() => openModal(props.slug)}
        />
      </td>
      <td className="text-center cursor-pointer">
        <OverlayTrigger
          trigger="click"
          placement={'left'}
          overlay={
            <Popover className={'popover-user-table'}>
              <Popover.Body className="p-0">
                <Nav className="flex-column">
                  <Nav.Link>Delete video</Nav.Link>
                </Nav>
              </Popover.Body>
            </Popover>
          }
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </OverlayTrigger>
      </td>
    </tr>
  )
}

export default UserTableRow

UserTableRow.propTypes = {
  id: PropTypes.number.isRequired,
  full_name: PropTypes.string.isRequired,
  votes: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  age: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  mobile: PropTypes.string.isRequired,
  videoSrc: PropTypes.string.isRequired,
  ranking: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
}
