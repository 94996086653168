import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

const YoutubeEmbed = ({ video, className, disableControls = false }) => {
  // const playerRef = useRef(null)
  const [ videoId, setVideoId ] = useState(null)


  useEffect(() => {
    const getEmbedId = (video) => {
      let index

      if(video.includes('embed')) {
        index = video.lastIndexOf('embed/')
      } else {
        index = video.lastIndexOf('=')
      }

      if (index !== -1) {
        return video.slice(index + 1)
      }

      return null
    }

    setVideoId(getEmbedId(video))
  }, [video])

  // useEffect(() => {
  //   // IFrame Player API.
  //   const tag = document.createElement('script')
  //   tag.src = 'https://www.youtube.com/iframe_api'
  //   const firstScriptTag = document.getElementsByTagName('script')[0]
  //   firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

  //   // Create the YouTube player
  //   window.onYouTubeIframeAPIReady = () => {
  //     playerRef.current = new window.YT.Player('player', {
  //       videoId: videoId,
  //       // suggestedQuality: 'highres',
  //       events: {
  //         onReady: (e) => {
  //           setInterval(() => onPlayerReady(e), 3000)
  //         },
  //       },
  //     })
  //   }

  //   // Clean up on component unmount
  //   return () => {
  //     if (playerRef.current) {
  //       playerRef.current.destroy()
  //     }
  //   }
  // }, [videoId])


  // const onPlayerReady = (event) => {
  //   event.target.playVideo()

  //   console.log(event)
  //   console.log(event.target.playerInfo.playbackQuality)
  //   const currentQuality = event.target.playerInfo.playbackQuality
  //   const availableQualityLevels = [...event.target.playerInfo.availableQualityLevels]
  //   console.log(availableQualityLevels)
  //   console.log(availableQualityLevels[0])
  //   event.target.setPlaybackQuality(availableQualityLevels[0])

  // }


  return (
    <div className="VideoDetailPage__VideoContainer--Youtube">
      {/* <div
        id="player"
        className={`VideoDetailPage__Video ${className}`}
      ></div> */}
      <iframe
        src={`${video}${disableControls ? `?controls=0&autoplay=1&mute=1&playsinline=1&playlist=${videoId}&loop=1 ` : ''}`}
        className={`VideoDetailPage__Video ${className} youtube-media`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  )
}

YoutubeEmbed.propTypes = {
  video: PropTypes.string.isRequired,
  className: PropTypes.string,
  disableControls: PropTypes.bool,
}

export default YoutubeEmbed
