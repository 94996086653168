import { Col, Form, Nav, Row, Spinner } from 'react-bootstrap'
import VideoCard from '../components/VideoCard'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { useContext, useEffect, useMemo, useState } from 'react'
import { getLeaderboard, getVideos } from '../api/videos'
import { useInfiniteQuery, useQuery } from 'react-query'
import { AppContext } from '../context'
import { Helmet } from 'react-helmet'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CompetitionsDropdown from '../components/CompetitionsDropdown'
import { getCompetitionsDetail } from '../api/competitions'
import { useMediaQuery } from '@uidotdev/usehooks'
import InspirationBanner from '../assets/landing/InspirationLogo.webp'
import InspirationLogoMobile from '../assets/landing/InspirationLogoMobile.webp'

const filtersTypes = { recommended: 'recommended', latest: '-upload_time', 'most-popular': '-votes', tiktok: 'TikTok', instagram: 'Instagram', youtube: 'Youtube' }

const AllVideos = () => {
  const { token, unregisteredUserId, user, timelineCompetitions } = useContext(AppContext)
  let [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width: 768px)')

  const [filter, setFilter] = useState(filtersTypes[searchParams.get('type')] ?? 'recommended')
  const [search, setSearch] = useState('')
  const [leaderboardVideos, setLeaderboardVideos] = useState([])
  const competition_id = Number(searchParams.get('competition_id'))

  useEffect(() => {
    if (!competition_id) {
      const defaultCompetitionId =
        timelineCompetitions.currentCompetition?.id ||
        (timelineCompetitions.lastCompetitions?.length ? timelineCompetitions.lastCompetitions[0].id : null)

      if (defaultCompetitionId) {
        navigate(`/all-videos/?competition_id=${defaultCompetitionId}`)
      } else {
        console.error('No hay currentCompetition ni lastCompetitions disponibles.')
      }
    }
  }, [competition_id, navigate, timelineCompetitions])

  const { data, isFetching, hasNextPage, fetchNextPage, refetch } =
    useInfiniteQuery(
      ['all-videos', filter, token, competition_id, unregisteredUserId, search],
      () =>
        getVideos({
          page: 1,
          per_page: 20,
          hosted_in: filter === 'TikTok' || filter === 'Instagram' || filter === 'Youtube' ? filter : '',
          order_by: filter === '-upload_time' ? filter : '',
          recommended: filter === 'recommended' ? filter : '',
          unregistered_user_id: !token ? unregisteredUserId : '',
          competition_id,
          search,
        }, token),
      {
        getNextPageParam: (lastPage) =>
          lastPage.actual_page < lastPage.total_pages
            ? Number(lastPage.actual_page) + 1
            : false,
        initialData: { pages: [{ data: [] }], pageParams: 1 },
        enabled: !!competition_id,
      }
    )

  const { data: competitionsDetail } = useQuery(
    ['getCompetitions', competition_id],
    () => {
      if (competition_id && competition_id !== 0) {
        return getCompetitionsDetail(competition_id)
      }
    },
    {
      initialData: {
        data: {
          name: '',
        },
        ok: false,
      },
    }
  )

  const { isFetching: isFetchingLeaderboard, data: RatingVideos } = useQuery(
    ['getLeaderboard', competition_id, token],
    () => getLeaderboard({ competition_id }, token),
    {
      enabled: filter === '-votes',
      onSuccess: (data) => setLeaderboardVideos(data),
      initialData: [],
    }
  )
  const videos = useMemo(
    () => {
      if (filter === '-votes' && leaderboardVideos.length > 0) {
        return leaderboardVideos
      }
      return Array.isArray(data?.pages) ? data.pages.reduce((prevVideos, page) => prevVideos.concat(page.data), []) : []
    },
    [data, leaderboardVideos, filter]
  )

  const handleFilter = (value) => {
    setFilter(value)
    setLeaderboardVideos([])
    setSearch('')
    setSearchParams((prev) => {
      const newParams = { type: value }
      if (prev.get('competition_id'))
        newParams.competition_id = prev.get('competition_id')
      return newParams
    })
  }

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  if(!competition_id) return null
  const a = true

  return (
    <main>
      <Helmet>
        <title>Watch videos | RFDS (Queensland Section) + Elders</title>
      </Helmet>
      <section className="Inspiration__Banner">
        <img
          className="Inspiration__Banner__Image"
          src={isMobile ? InspirationLogoMobile : InspirationBanner}
          alt="bannerBackground"
        />
        <p className='text-center'>Watch & vote</p>
      </section>
      <Container className="WatchNow-container">
        {competitionsDetail.ok && (
          <>
            <div className='d-flex flex-column gap-3 mt-4'>
              <h4>Competition Year: {competitionsDetail.data.name}</h4>
              <p>{competitionsDetail.data.explanation}</p>
            </div>
          </>
        )}
        <div className='d-flex gap-2 w-100 justify-content-start align-items-center mt-3'>
          <Form.Control
            type="text"
            placeholder='Search by video name or entrant'
            value={search}
            onChange={handleSearch}
            style={{ width: '100%', maxWidth: '700px' }}
          />
        </div>
        <CompetitionsDropdown className='mobile' />
        <div className='d-flex justify-content-between align-items-center'>
          <Nav
            variant="tabs"
            defaultActiveKey={filtersTypes[searchParams.get('type')] ?? 'recommended'}
            className="my-4 AllVideos d-flex gap-3"
            onSelect={handleFilter}
          >
            <Nav.Item>
              <Nav.Link className='w-max-cont' eventKey="recommended">Recommended</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className='w-max-cont' eventKey="-upload_time">Latest</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className='w-max-cont' eventKey="-votes">Most popular</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className='w-max-cont' eventKey="TikTok">TikTok</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className='w-max-cont' eventKey="Instagram">Instagram</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className='w-max-cont' eventKey="Youtube">YouTube</Nav.Link>
            </Nav.Item>
          </Nav>
          <CompetitionsDropdown />
        </div>
        <Row className="mb-4">
          {Array.isArray(videos) && videos.length > 0 && (
            videos?.map((element) => (
              <Col xl={3} md={4} sm={12} key={element.id}>
                <VideoCard
                  id={element.id}
                  socialNet={element.hosted_in}
                  likes={element.votes}
                  name={element.name}
                  videoName={element.name}
                  videoSrc={element.video_link}
                  firstName={element.user_name}
                  lastName={element.user_last_name}
                  description={element.description}
                  competitionYear={element.competition_name}
                  slug={element.slug}
                  staffPick={element.is_staff_pick}
                  new={element.new}
                  image={element.thumbnail}
                  voted={element.already_voted}
                  votes={element.votes}
                  video={element}
                  refetch={refetch}
                />
              </Col>
            )))}
        </Row>
        {isFetching || isFetchingLeaderboard ? (
          <div className="text-center py-4">
            <Spinner animation="border" variant="warning" />
          </div>
        ) : hasNextPage && filter !== '-votes' ? (
          <div className="text-center mb-5" onClick={() => fetchNextPage()}>
            <Button variant="rs-third">Load more videos</Button>
          </div>
        ) : videos.length !== 0 ? (
          <div style={{ height: '86px' }} />
        ) : search.length !== 0 ? (
          <div className='w-100 d-flex justify-content-center'>No results to show.</div>
        ) : (
          <div className='w-100 d-flex justify-content-center'>No videos to view.</div>
        )}
      </Container>
    </main>
  )
}

export default AllVideos
