import { useState, useEffect, useContext } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../context'
import PropTypes from 'prop-types'

const VoteVideoModal = ({ show, onClose, initialVotes, onSubmit, token, isVoted }) => {
  const { remainingVotes } = useContext(AppContext)
  const [amountLikes, setAmountLikes] = useState(initialVotes)
  const [disableLike, setDisableLike] = useState(false)

  useEffect(() => {
    setAmountLikes(initialVotes)
  }, [initialVotes])

  const handleMinusVote = () => {
    if (amountLikes > 0) {
      setAmountLikes(amountLikes - 1)
    }
  }

  const handlePlusVote = () => {
    const maxVotes = token ? Math.min(remainingVotes + initialVotes, 5) : 1
    if (amountLikes < maxVotes) {
      setAmountLikes(amountLikes + 1)
    }
  }

  const handleVoteSubmit = async () => {
    if (disableLike) return

    setDisableLike(true)
    onClose()
    const isRemoveVote = token ? amountLikes === 0 : isVoted
    await onSubmit(amountLikes, isRemoveVote)
    setDisableLike(false)
  }
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Body style={{ minWidth: '300px' }}>
        <div className="d-flex justify-content-center w-100 gap-1 align-items-center mb-5">
          <h3 className='mt-2'>Confirm votes</h3>
          <FontAwesomeIcon icon={faX} size="md" className="cursor-pointer position-absolute top-0 r-0" onClick={onClose} style={{ right: '0px' }} />
        </div>
        {token ? (
          <>
            <p className="text-center my-3">How many votes would you like to cast for this video?</p>
            <div className="d-flex justify-content-center align-items-center gap-5 mb-5">
              <FontAwesomeIcon icon={faMinus} size="lg" className="cursor-pointer" onClick={handleMinusVote} />
              <div className="d-flex flex-column align-items-center">
                <p className="mb-0">{amountLikes}</p>
                <p className="mb-0">Vote{amountLikes > 1 ? 's' : ''}</p>
              </div>
              <FontAwesomeIcon icon={faPlus} size="lg" className="cursor-pointer" onClick={handlePlusVote} />
            </div>
            <Button variant="rs" className="w-100" onClick={handleVoteSubmit} disabled={disableLike}>
              Confirm votes
            </Button>
          </>
        ) : !isVoted ? (
          <>
            <div className='d-flex flex-column w-100 gap-2 justify-content-center align-items-center my-4'>
              <p className="text-4 text_primary">
                Do you want to confirm your vote?
              </p>
            </div>
            <Button variant="rs" className="w-100 mt-3" onClick={handleVoteSubmit} disabled={disableLike}>
              Confirm vote
            </Button>
          </>
        ) : (
          <>
            <p className="text-center mb-5">Are you sure you want to remove your vote for this video?</p>
            <div className="gap-3 mt-auto d-flex justify-content-between">
              <Button
                variant="rs-border"
                onClick={onClose}
                className='w-100'
              >
                No
              </Button>
              <Button
                variant="rs"
                className='w-100'
                onClick={handleVoteSubmit}
                disabled={disableLike}
              >
                Confirm
              </Button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

VoteVideoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialVotes: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  token: PropTypes.string,
  isVoted: PropTypes.bool,
}

export default VoteVideoModal
