import { Helmet } from 'react-helmet'
import InspirationBanner from '../assets/landing/InspirationLogo.webp'
import InspirationLogoMobile from '../assets/landing/InspirationLogoMobile.webp'
import IdeaBanner from '../assets/landing/IdeaBanner.webp'
import IdeaBannerMobile from '../assets/landing/IdeaBannerMobile.webp'
import { Button } from 'react-bootstrap'
import { useContext, useState } from 'react'
import classNames from 'classnames'
import YoutubeEmbed from '../components/YoutubeEmbed'
import { useMediaQuery } from '@uidotdev/usehooks'
import { ideasList } from '../constants/Ideas.js'
import InstagramEmbed from '../components/InstagramEmbed.jsx'
import { AppContext } from '../context/index.js'

const Inspiration = () => {
  const [idea, setIdea] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const isMobile = useMediaQuery('(max-width: 768px)')
  const { competitionInfo } = useContext(AppContext)

  const generateRandomIdea = () => {
    const randomIndex = Math.floor(Math.random() * ideasList.length)
    setIdea(ideasList[randomIndex])
    setButtonDisabled(true)

    setTimeout(() => {
      setButtonDisabled(false)
    }, 2000)

    setTimeout(() => {
      setIdea('')
    }, 15000)
  }

  const handleVideo = () => {
    if (
      !competitionInfo ||
      !competitionInfo.competition ||
      !competitionInfo.competition.video_example ||
      !competitionInfo.competition.video_example.hosted_in ||
      !competitionInfo.competition.video_example.video_link
    ) {
      return <p>No video available</p>
    }

    const { hosted_in, video_link } = competitionInfo.competition.video_example

    switch (hosted_in) {
    case 'Youtube':
      return <YoutubeEmbed video={video_link} className="HowToEnter__Video" />
    case 'TikTok':
      return (
        <div
          style={{
            left: 0,
            width: '100%',
            height: 738,
            position: 'relative',
          }}
        >
          <iframe
            style={{
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              position: 'absolute',
              border: 0,
            }}
            className="embed-responsive-item"
            src={video_link}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded video"
          />
        </div>
      )
    case 'Instagram':
      return <InstagramEmbed videoSrc={video_link} />
    default:
      return <></>
    }
  }

  return (
    <main>
      <Helmet>
        <title>Be inspired | RFDS (Queensland Section) + Elders</title>
      </Helmet>
      <section className="Inspiration__Banner">
        <img
          className="Inspiration__Banner__Image"
          src={isMobile ? InspirationLogoMobile : InspirationBanner}
          alt="bannerBackground"
        />
        <p className='text-center'>Be inspired</p>
      </section>
      <section className="Inspiration__Texts">
        <div className="d-flex flex-column justify-content-center w-100 gap-4" style={{ maxWidth: '700px' }}>
          <p className="text-rs-blue caption-2">Queensland&apos;s beauty takes many forms</p>
          <p className="ffam-FreeSans text-3">
            Unsure of what to capture in your video? Welcome to the Inspiration Page! Here, we aim to spark your creativity and help you brainstorm ideas for your Outback Explorer Competition video.
          </p>
          <p className="ffam-FreeSans text-3">
            Queensland is a land of incredible variety, from its stunning landscapes and vibrant wildlife to its rich cultural heritage and close-knit communities.
          </p>
          <p className="ffam-FreeSans text-3">
            We want to see your unique perspective on what makes Queensland special to you. Whether it&apos;s capturing a breathtaking sunrise over the outback, showcasing the charm of your local town, or sharing your favourite outdoor adventure, your story matters.
          </p>
          <p className="ffam-FreeSans text-3">
            Dive in, get inspired, and show us what you love about Queensland!
          </p>
        </div>
      </section>
      <section className="Inspiration__Banner" style={{ padding: '0 16px' }}>
        <img className="Inspiration__Banner__Image2" src={isMobile ? IdeaBannerMobile : IdeaBanner} alt="bannerBackground" />
        <div className='Inspiration__Banner__Container'>
          <p className='text-center mt-4 mt-lg-0'>Still stuck for ideas?</p>
          <Button variant='rs' onClick={generateRandomIdea} style={{ width: 'max-content' }} disabled={buttonDisabled}>
            Generate idea
          </Button>
          <div
            className={classNames('Inspiration__Form mb-4 mb-lg-0', {
              'HasIdea': idea,
            })}
          >
            {idea ? idea : 'Click on the above button to generate an idea!'}
          </div>
        </div>
      </section>
      <section className="Inspiration__Video">
        <div className="Inspiration__Video__Container">
          <p className="text-rs-blue caption-2">Watch our example video!</p>
          {handleVideo()}
        </div>
      </section>
    </main>
  )
}

export default Inspiration