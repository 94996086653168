
import { faArrowUpRightFromSquare, faEllipsisVertical, faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { Nav, OverlayTrigger, Popover } from 'react-bootstrap'
import { getVideosDetails } from '../api/videos'
import { AppContext } from '../context'

const UserTableMobile = 
({ video }) => {
  const { token, openVideoModal } = useContext(AppContext)

  const openModal = (id) => {
    const getVideoInfo = async () => {
      const response = await getVideosDetails(token, id)

      if (response.error) console.error(response.error)
      else if (response) {
        openVideoModal({...response, isAdmin: true})
      }
    }
    getVideoInfo()
  }

  return (
    <>
      <div className="AdminUsers__Table_Mobile_Item">
        <div className='AdminUsers__Table_Mobile_Item_Header'>
          <span className='text-4 text_primary fw-semibold'>#{video.ranking} {video.full_name}</span>
          <div className="text-center cursor-pointer">
            <OverlayTrigger
              trigger="click"
              placement={'left'}
              overlay={
                <Popover className={'popover-user-table'}>
                  <Popover.Body className="p-0">
                    <Nav className="flex-column">
                      <Nav.Link>Delete video</Nav.Link>
                    </Nav>
                  </Popover.Body>
                </Popover>
              }
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </OverlayTrigger>
          </div>
        </div>
        <div className='AdminUsers__Table_Mobile_Item_Full d-flex flex-column'>
          <span className='text-6 text_Secondary fw-bold'>
                    Email
          </span>
          <span className='text-5 text_primary fw-500'>
            {video.email}
          </span>
        </div>
        <div className='d-flex flex-column'>
          <span className='text-6 text_Secondary fw-bold'>
                Age
          </span>
          <span className='text-5 text_primary fw-500'>
            {video.age}
          </span>
        </div>
        <div className='d-flex flex-column'>
          <span className='text-6 text_Secondary fw-bold'>
                Mobile
          </span>
          <span className='text-5 text_primary fw-500'>
            {video.mobile}
          </span>
        </div>
        <div className='d-flex flex-column'>
          <span className='text-6 text_Secondary fw-bold'>
                Country
          </span>
          <span className='text-5 text_primary fw-500'>
            {video.country}
          </span>
        </div>
        <div className='d-flex flex-column'>
          <span className='text-6 text_Secondary fw-bold'>
                City
          </span>
          <span className='text-5 text_primary fw-500'>
            {video.city}
          </span>
        </div>
        <div className='AdminUsers__Table_Mobile_Item_Footer'>
          <p className="mb-0 text-4 fw-bold"><FontAwesomeIcon icon={faHeart} className="me-2" /> {video.votes}</p>
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="cursor-pointer" onClick={() => openModal(video.slug)} />
        </div>
      </div>
    </>
  )
}

UserTableMobile.propTypes = {
  video: PropTypes.object.isRequired
}

export default UserTableMobile