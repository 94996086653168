import { Col, Row, Container, InputGroup, FormControl } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
// import AuthImg from '../../assets/auth/authimg.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useContext, useState } from 'react'
import { checkPasswordRecoveryToken, passwordRecovery, passwordRecoveryConfirm } from '../../api/users'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { useNavigate } from 'react-router'
import checkIcon from '../../assets/landing/checkIcon.png'
import { AppContext } from '../../context'
import { Helmet } from 'react-helmet'

const ForgotPassword = () => {
  const { userLog } = useContext(AppContext)
  const [step, setStep] = useState(1)
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

  const handlerShowPassword = () => {
    setShowPassword((prev) => !prev)
  }
  const handlerShowPasswordConfirm = () => {
    setShowPasswordConfirm((prev) => !prev)
  }

  const goToLogin = () => { 
    navigate('/login')
  }

  const clearErrorsAfterTimeout = (formik) => {
    setTimeout(() => {
      Object.keys(formik.errors).forEach(key => {
        formik.setFieldError(key, '')
      })
    }, 5000)
  }

  const clearYupErrors = (formik) => {
    setTimeout(() => {
      formik.setErrors({})
    }, 5000)
  }

  const formik1 = useFormik({
    initialValues: {
      email: userLog.email,
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .required('E-mail is required.')
        .max(60, 'Maximum characters: 60.'),
    }),
    onSubmit: async ({ email }) => {
      if (email.includes(' ')) {
        email = email.replace(/ /g, '')
      }

      try {
        const response = await passwordRecovery({ email })
        if (response.status === 400) {
          if (response.data.error) {
            formik1.setFieldError('email', response.data.error)
            clearErrorsAfterTimeout(formik1)
          }
        } else if (response.message === 'Email sent') {
          setStep((prev) => prev + 1)
        }
      } catch (error) {
        console.error('An unexpected error occurred:', error)
      }
    },
    validate: (values) => {
      clearYupErrors(formik1)
    },
  })

  const formik2 = useFormik({
    initialValues: {
      token: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      token: Yup.string()
        .required('Token is required.')
        .max(60, 'Maximum characters: 60.'),
    }),
    onSubmit: async ({ token }) => {
      try {
        const response = await checkPasswordRecoveryToken({
          email: formik1.values.email,
          token,
        })
        if (response.status === 400) {
          if (response.data.error) {
            formik2.setFieldError('token', response.data.error)
            clearErrorsAfterTimeout(formik2)
          }
        } else if (response.message === 'Token is valid') {
          setStep((prev) => prev + 1)
        }
      } catch (error) {
        console.error('An unexpected error occurred:', error)
      }
    },
    validate: (values) => {
      clearYupErrors(formik2)
    },
  })

  const formik3 = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Password is required.')
        .min(5, 'Minimum characters: 5.')
        .max(40, 'Maximum characters: 40.'),
      passwordConfirm: Yup.string()
        .required('Confirm password is required.')
        .min(5, 'Minimum characters: 5.')
        .max(40, 'Maximum characters: 40.'),
    }),
    onSubmit: async ({ password, passwordConfirm }) => {
      if (password !== passwordConfirm) {
        formik3.setFieldError('password', 'Passwords don\'t match')
        formik3.setFieldError('passwordConfirm', 'Passwords don\'t match')
        clearErrorsAfterTimeout(formik3)
        return null
      }
      try {
        const response = await passwordRecoveryConfirm({
          email: formik1.values.email,
          token: formik2.values.token,
          password,
        })

        if (response.status === 400) {
          if (response.data.password) {
            formik3.setFieldError('password', response.data.password[0])
            clearErrorsAfterTimeout(formik3)
          }
          if (response.data.passwordConfirm) {
            formik3.setFieldError('passwordConfirm', response.data.passwordConfirm[0])
            clearErrorsAfterTimeout(formik3)
          }
        } else {
          setStep((prev) => prev + 1)
        }
      } catch (error) {
        console.error('An unexpected error occurred:', error)
      }
    },
    validate: (values) => {
      clearYupErrors(formik3)
    },
  })

  return (
    <>
      <Helmet>
        <title>Reset Password | Outback Explorer Competition</title>
      </Helmet>
      <div className="AuthContainer">
        <Container fluid>
          <Row>
            <Col
              md={{ span: 8, offset: 2 }}
              lg={{span: 6, offset: 3 }}
              xxl={{ span: 4, offset: 4 }}
              className="mt-5"
            >
              <div className="bg-white AuthBox mb-4">
                <Container fluid>
                  <Row>
                    <Col className="p-4">
                      <h1 className="mb-4 text-1 fw-600 text-center">Reset password</h1>
                      {step === 1 && (
                        <Form className="mb-4" onSubmit={formik1.handleSubmit}>
                          <p className="text-5 ffam-FreeSans text-center mb-4">
                          Please enter your email and we will send a code to reset your password.
                          </p>
                          <Form.Group className="position-relative mb-4 fw-bold" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              value={formik1.values.email}
                              onChange={formik1.handleChange}
                              isInvalid={formik1.errors.email}
                            />
                            <Form.Control.Feedback className="text-red ffam-FreeSans fw-400 text-5" type="invalid">
                              {formik1.errors.email}
                            </Form.Control.Feedback>
                          </Form.Group>
 
                          <div className="d-grid gap-2 mt-auto pt-2">
                            <Button variant="rs" type="submit">
                            Send code
                            </Button>
                          </div>
                        </Form>
                      )}
                      {step === 2 && (
                        <Form className="mb-4" onSubmit={formik2.handleSubmit}>
                          <p className='text-5 ffam-FreeSans text-center mb-4'>Please input the code we sent to your email.</p>
                          <Form.Group className="position-relative mb-4 fw-bold" controlId="token">
                            <Form.Label>Code</Form.Label>
                            <Form.Control
                              type="text"
                              value={formik2.values.token}
                              onChange={formik2.handleChange}
                              isInvalid={formik2.errors.token}
                            />
                            <Form.Control.Feedback className="text-red ffam-FreeSans fw-400 text-5" type="invalid">
                              {formik2.errors.token}
                            </Form.Control.Feedback>
                          </Form.Group>
 
                          <div className="d-grid gap-2 mt-auto pt-2">
                            <Button variant="rs" type="submit" disabled={!formik2.isValid || !formik2.dirty}>
                            Confirm code
                            </Button>
                          </div>
                        </Form>
                      )}
                      {step === 3 && (
                        <Form className="mb-4" onSubmit={formik3.handleSubmit}>
                          <p className='text-5 ffam-FreeSans text-center mb-4'>Input new password.</p>
                          <Form.Group className="mb-4 fw-bold" controlId="password">
                            <Form.Label>Password</Form.Label>
                            <InputGroup className="position-relative">
                              <FormControl
                                type={showPassword ? 'text' : 'password'}
                                value={formik3.values.password}
                                onChange={formik3.handleChange}
                                isInvalid={!!formik3.errors.password}
                                autoComplete="current-password"
                                style={{ borderRadius: '6px' }}
                              />
                              <div
                                className="btn-outline-secondary d-flex align-items-center position-absolute end-0 top-0 h-100 translate-middle-x z-index-1"
                              >
                                {showPassword ? (
                                  <FontAwesomeIcon icon={faEyeSlash} onClick={handlerShowPassword} />
                                ) : (
                                  <FontAwesomeIcon icon={faEye} onClick={handlerShowPassword} />
                                )}
                              </div>
                              <Form.Control.Feedback className="text-red ffam-FreeSans fw-400 text-5" type="invalid">
                                {formik3.errors.password}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>

                          <Form.Group
                            className="mb-4 fw-bold"
                            controlId="passwordConfirm"
                          >
                            <Form.Label>Confirm password</Form.Label>
                            <InputGroup className="position-relative">
                              <FormControl
                                type={showPasswordConfirm ? 'text' : 'password'}
                                value={formik3.values.passwordConfirm}
                                onChange={formik3.handleChange}
                                isInvalid={!!formik3.errors.passwordConfirm}
                              />
                              <div
                                className="btn-outline-secondary d-flex align-items-center position-absolute end-0 top-0 h-100 translate-middle-x z-index-1"
                              >
                                {showPassword ? (
                                  <FontAwesomeIcon icon={faEyeSlash} onClick={handlerShowPasswordConfirm} />
                                ) : (
                                  <FontAwesomeIcon icon={faEye} onClick={handlerShowPasswordConfirm} />
                                )}
                              </div>
                              <Form.Control.Feedback className="text-red ffam-FreeSans fw-400 text-5" type="invalid">
                                {formik3.errors.passwordConfirm}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
 
                          <div className="d-grid gap-2 mt-auto pt-2">
                            <Button variant="rs" type="submit">
                            Change Password
                            </Button>
                          </div>
                        </Form>
                      )}
                      {step === 4 && (
                        <div className="d-flex flex-column justify-content-evenly align-items-center h-75 mb-4">
                          <div className='d-flex flex-column mb-4 justify-content-center align-items-center gap-3'>
                            <img src={checkIcon} alt="check icon" className='check-icon' />
                            <p className='text-3'>Password changed successfully</p>
                          </div>
                          <Button variant="rs" onClick={goToLogin}>
                          Go back to login
                          </Button>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}


export default ForgotPassword