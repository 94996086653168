import React, { useEffect, useRef, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import Video from '../assets/videos/apple-frame.mp4'

const isAppleDevice = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)

const NewParallaxApple = () => {
  const containerRef = useRef(null)
  const videoRef = useRef(null)

  useEffect(() => {
    const container = containerRef.current
    const video = videoRef.current
    if (container && video) {
      let isScrolling = false
      const containerHeight = container.getBoundingClientRect().height

      const updateVideo = (e) => {
        const scroll = e.target.scrollTop
        const containerTop = container.offsetTop
        const scrollProgress = (scroll - containerTop) / (containerHeight - window.innerHeight)

        const videoDuration = video.duration
        const videoProgress = scrollProgress * videoDuration

        if (video.currentTime !== null) {
          video.currentTime = videoProgress
        }
      }

      const handleScroll = (e) => {
        if (!isScrolling) {
          isScrolling = true
          requestAnimationFrame(() => {
            updateVideo(e)
            isScrolling = false
          })
        }
      }

      const content = document.querySelector('.content')
      content.addEventListener('scroll', handleScroll)
    }
  }, [videoRef, containerRef])

  return (
    <div
      className='NewParallax'
      ref={containerRef}
    >
      <video
        className='NewParallax__Frame'
        ref={videoRef}
        src={Video}
        preload='auto'
        muted
      ></video>
    </div>
  )
}

const NewParallaxOtherDevices = () => {
  const containerRef = useRef(null)
  const [currentFrame, setCurrentFrame] = useState(0)
  const [images, setImages] = useState([])

  useEffect(() => {
    if (images.length > 0) return
    // Precargar todas las imágenes
    const frames = Array.from({ length: 283 }, (_, i) =>
      // eslint-disable-next-line no-undef
      require(`../assets/videos/frames/fotograma_${String(i + 1).padStart(4, '0')}.jpg`)
    )

    const loadedImages = frames.map((src) => {
      const img = new Image()
      img.src = src
      return img
    })

    setImages(loadedImages)
  }, [])

  useEffect(() => {
    const container = containerRef.current
    if (container && images.length > 0) {
      let isScrolling = false
      const containerHeight = container.getBoundingClientRect().height

      const updateFrame = (scroll) => {
        const containerTop = container.offsetTop
        const scrollProgress = (scroll - containerTop) / (containerHeight - window.innerHeight)

        const totalFrames = images.length
        const frameIndex = Math.min(totalFrames - 1, Math.max(0, Math.floor(scrollProgress * totalFrames)))

        setCurrentFrame(frameIndex)
      }

      const handleScroll = (e) => {
        if (!isScrolling) {
          isScrolling = true
          requestAnimationFrame(() => {
            updateFrame(e.target.scrollTop)
            isScrolling = false
          })
        }
      }

      const content = document.querySelector('.content')
      content.addEventListener('scroll', handleScroll)

      return () => {
        content.removeEventListener('scroll', handleScroll)
      }
    }
  }, [containerRef, images])

  return (
    <div className="NewParallax" ref={containerRef}>
      <AnimatePresence>
        {images.length > 0 && (
          <motion.img
            className="NewParallax__Frame"
            src={images[currentFrame].src}
            alt="Parallax Frame"
            key={currentFrame}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

const NewParallax = () => {
  return isAppleDevice ? <NewParallaxApple /> : <NewParallaxOtherDevices />
}

export default NewParallax
