import { Card, Stack } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInstagram,
  faTiktok,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
// import { faHeart } from '@fortawesome/free-regular-svg-icons'
// import { faHeart as faSolidHearth } from '@fortawesome/free-solid-svg-icons'
import { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as constants from '../constants'
import { AppContext } from '../context'
import { useNavigate } from 'react-router-dom'

const VideoCard = (props) => {
  const navigate = useNavigate()
  const { openVideoModal, viewVideoDetail } = useContext(AppContext)
  // const [liked, setLiked] = useState(true)
  // const [likes, setLikes] = useState(0)
  const [icon, setIcon] = useState(null)

  useEffect(() => {
    if (props.socialNet === 'TikTok') {
      setIcon(faTiktok)
    } else if (props.socialNet === 'Youtube') {
      setIcon(faYoutube)
    } else if (props.socialNet === 'Instagram') {
      setIcon(faInstagram)
    }

    // setLikes(props.likes)
    // setLiked(props.voted)
  }, [])

  // useEffect(() => {
  //   setLikes(props.likes)
  //   setLiked(props.voted)
  // }, [props.likes, props.voted])
  const handleClick = () => {
    viewVideoDetail({ ...props.video, refetch: props.refetch })
    navigate(`/${props.competitionYear}/${props.slug}`)
  }
  return (
    <>
      <div className="cursor-pointer mb-4" onClick={handleClick}>
        <Card className="video-card crop">
          {props.image && (
            <Card.Img
              src={`${props.image.indexOf('http://') === 0 || props.image.indexOf('https://') === 0
                ? ''
                : constants.URL
              }${props.image}`}
              alt="Card image"
            />
          )}
          <Card.ImgOverlay className="text-white h-100 d-flex flex-column justify-content-end">
            <div className="Card_Info">
              {props.staffPick && (
                <div className="Card_Info_Item">
                  <p>Staff pick</p>
                </div>
              )}
              {props.video?.new && (
                <div className="Card_Info_Item__White">
                  <p>new</p>
                </div>
              )}
            </div>
            <div>
              <Stack
                direction="horizontal"
                gap={4}
                className="justify-content-center"
              >
                {icon ? <FontAwesomeIcon icon={icon} size="xl" /> : null}
                {/* <div>
                  { liked ?
                    <FontAwesomeIcon icon={faSolidHearth} size="xl" className="me-2" /> :
                    <FontAwesomeIcon icon={faHeart} size="xl" className="me-2" />
                  }
                  {likes}
                </div> */}
              </Stack>
            </div>
          </Card.ImgOverlay>
        </Card>
        <div className="mt-2">
          <p className='Card__Name'>{props.videoName}</p>
          <p className='Card__UserName'>
            {props.firstName} {props.lastName}
          </p>
        </div>
      </div>
    </>
  )
}

export default VideoCard

VideoCard.propTypes = {
  video: PropTypes.object,
  slug: PropTypes.string.isRequired,
  socialNet: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired,
  videoName: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  competitionYear: PropTypes.string,
  competitionId: PropTypes.string,
  description: PropTypes.string.isRequired,
  videoSrc: PropTypes.string.isRequired,
  staffPick: PropTypes.bool.isRequired,
  new: PropTypes.bool.isRequired,
  image: PropTypes.string.isRequired,
  voted: PropTypes.bool.isRequired,
  refetch: PropTypes.func,
}
