import {Row, Spinner, Modal, InputGroup, Form} from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import {useInfiniteQuery} from 'react-query'
import {getVideos} from '../../api/videos'
import {useContext, useMemo, useEffect} from 'react'
import { AppContext } from '../../context'
import { approveVideo, patchEditVideo} from '../../api/videos'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDotCircle, faCircle } from '@fortawesome/free-regular-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import AdminAcceptedVideoCard from '../../components/AdminAcceptedVideoCard'
import { REJECT_OPTIONS } from '../../constants'


const AdminVideos = () => {
  const { token } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [searchWord, setSearchWord] = useState('')
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [rejectSelected, setRejectSelected] = useState('Your video is more than 2 minutes long.')
  const handleClose = () => {setShow(false) ; setSelectedVideo(null); setRejectSelected('Your video is more than 2 minutes long.')}

  const { data, isFetching, hasNextPage, fetchNextPage, refetch } =
    useInfiniteQuery(
      ['approved_videos', token, searchWord],
      ({ pageParam = 1 }) =>
        getVideos({
          page: pageParam,
          status: 'Approved',
          per_page: 12,
          all: 'true',
          search: searchWord
        }, token),
      {
        getNextPageParam: (lastPage) =>
          lastPage.actual_page < lastPage.total_pages
            ? Number(lastPage.actual_page) + 1
            : false,
        initialData: { pages: [{ data: [] }], pageParams: 1 },
        enabled: token !== '',
      }
    )
  const videos = useMemo(
    () =>
      data.pages.reduce((prevVideos, page) => prevVideos.concat(page.data), []),
    [data]
  )

  useEffect(() => {
    refetch()
  }, [searchWord])

  const onChangeSearch = (query) => {
    setSearchWord(query)
  }

  const staffPickVideo = (videoid, is_staff_pick) => {
    const body = {
      is_staff_pick: is_staff_pick,
    }
    patchEditVideo(token, videoid , body)
      .then(() => {
        refetch()
      })
  }

  const rejectVideoModal = (videoid) => {
    setShow(true)
    setSelectedVideo(videoid)
  }

  const removeRecommended = async (videoid, recommended) => {
    const body = {
      recommended,
    }
    const response = await patchEditVideo(token, videoid , body)

    if(response.error) console.error(response.error)
    else if (response) {
      refetch()
    }
  }

  const bannedLeaderboardVideo = (videoid, is_banned_from_leaderboard) => {
    const body = {
      is_banned_from_leaderboard: is_banned_from_leaderboard,
    }
    patchEditVideo(token, videoid , body)
      .then(() => {
        refetch()
      })
  }

  const rejectVideo = () => {
    const body = {
      status : 'Rejected',
      reason: rejectSelected,
    }
    approveVideo(token, selectedVideo, body)
      .then(() => {
        setLoading(true)
        refetch()
        setLoading(false)
        setShow(false)
      })
  }

  const pendingVideo = (videoid) => {
    const body = {
      status : 'Pending',
    }
    approveVideo(token, videoid, body)
      .then(() => {
        setLoading(true)
        refetch()
        setLoading(false)
      })
  }


  return (
    <>
      <InputGroup className="mb-3">
        <InputGroup.Text className="bg-white border-0 ms-n5" id="basic-addon1">
          <FontAwesomeIcon icon={faMagnifyingGlass} size={'lg'} />
        </InputGroup.Text>
        <Form.Control
          aria-label="search"
          value={searchWord}
          onChange={(e) => onChangeSearch(e.target.value)}
          aria-describedby="basic-addon1"
          style={{ backgroundColor: '#EEEEEE', border: 'none' }}
        />
      </InputGroup>
      {token === '' || isFetching || loading ? (
        <div className="text-center" style={{ marginBottom: '49.25px' }}>
          <Spinner animation="border" variant="warning" />
        </div>
      ) : videos.length === 0 ? (
        <div>No accepted videos</div>
      ) : (
        <>
          <Row className="mb-4">
            {videos.length !== 0 ? videos.map((video) =>
              <AdminAcceptedVideoCard
                video={video}
                key={video.id}
                pendingVideo={pendingVideo}
                staffPickVideo={staffPickVideo}
                rejectVideoModal={rejectVideoModal}
                id={video.id}
                socialNet={video.hosted_in}
                likes={video.votes}
                name={video.name}
                videoName={video.name}
                videoSrc={video.video_link}
                firstName={video.user_name}
                lastName={video.user_last_name}
                description={video.description}
                staffPick={video.is_staff_pick}
                recommended={video.recommended}
                removeRecommended={removeRecommended}
                image={video.thumbnail}
                email={video.user_email}
                age={video.user_age}
                mobile={video.user_phone}
                city={video.user_city}
                country={video.user_country}
                voted={video.already_voted}
                bannedLeaderboardVideo={bannedLeaderboardVideo}
                bannedLeaderboard={video.is_banned_from_leaderboard}
              />
            ): <></>}
          </Row>
        </>
      )}

      {hasNextPage?
        <div className="text-center mb-5" onClick={() => fetchNextPage()}>
          <Button variant="rs-third">Load more videos</Button>
        </div>
        : null
      }

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select a reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="h-100 d-flex flex-column p-4">
            <div className="d-flex flex-column gap-3 mt-2">
              {REJECT_OPTIONS.map((option, index) => (
                <div
                  key={index}
                  className="d-flex cursor-pointer"
                  onClick={() => setRejectSelected(option)}
                >
                  {rejectSelected === option ? (
                    <FontAwesomeIcon
                      icon={faDotCircle}
                      size="lg"
                      className="me-2"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCircle}
                      size="lg"
                      className="me-2"
                    />
                  )}{' '}
                  {option}
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => rejectVideo()}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AdminVideos