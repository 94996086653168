import { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { AppContext } from '../context'
import 'react-quill/dist/quill.snow.css'

const TermsAndConditions = () => {
  const {
    token,
    timelineCompetitions,
  } = useContext(AppContext)

  return (
    <>
      <Helmet>
        <title>Terms & conditions | Outback Explorer Competition</title>
      </Helmet>
      {timelineCompetitions.currentCompetition && !timelineCompetitions.nextCompetition && (
        <>
          <section className="TermsAndConditions">
            <div className="my-4 admin-description" dangerouslySetInnerHTML={{ __html: timelineCompetitions.currentCompetition.terms_and_conditions }} />
          </section>
        </>
      )}
      {timelineCompetitions.lastCompetitions && timelineCompetitions.lastCompetitions.length !== 0 && !timelineCompetitions.nextCompetition && !timelineCompetitions.currentCompetition && (
        <>
          <section className="TermsAndConditions">
            <div className="my-4 admin-description" dangerouslySetInnerHTML={{ __html: timelineCompetitions.lastCompetitions[0].terms_and_conditions }} />
          </section>
        </>
      )}
      {timelineCompetitions.nextCompetition && (
        <>
          <section className="TermsAndConditions">
            <div className="my-4 admin-description" dangerouslySetInnerHTML={{ __html: timelineCompetitions.nextCompetition.terms_and_conditions }} />
          </section>
        </>
      )}

      {/* <h5 className="text-4 text-center">
          Outback Explorer Competition Terms & conditions
        </h5>
        <p className="text-5">Terms and Conditions</p>
        <ol className="text-5">
          <li>
            The terms and conditions contained herein, together with any other
            information on how to enter and win prizes in the competition
            referred to as the “Outback Explorer Competition” or such other
            similar reference (“Competition”), comprise the terms and conditions
            (“Terms and Conditions”) to which you submit when entering the
            Competition.
          </li>
          <li>
            If you submit an entry or read the Terms and Conditions and
            thereafter take any steps howsoever with respect to submitting an
            entry, or otherwise participate in this Competition, you are deemed
            to have accepted these Terms and Conditions and for the purposes of
            these Terms and Conditions you are referred to as an “Entrant”.
          </li>
          <li>
            The promotor is RFDS (Qld) Services Limited (ABN 26 161 472 199) of
            12 Casuarina Street, Brisbane Airport QLD 4008 (“Promoter”).
          </li>
        </ol>
        <p className="text-5">Eligibility</p>
        <ol start={4} className="text-5">
          <li>
            An Entrant from anywhere in the world may enter the Competition.
          </li>
          <li>
            An Entrant must be the age of 13 years or over. Entrants under the
            age of 18 years must obtain prior permission of their parent or
            legal guardian. Promotor may verify that permission has been given
            and obtain agreement from parent or guardian to the competition
            terms and conditions. Failure to agree may disqualify the Entrant.
          </li>
          <li>
            Employees, and their immediate family, of Promotor are not eligible
            to enter this Competition. Immediate family means any of the
            following: spouse, ex-spouse, de facto spouse, child or step child
            (whether natural or by adoption), parent, step parent, grandparent,
            step grandparent, uncle, aunt, niece, nephew, brother, sister, step
            brother, step sister or first cousin.
          </li>
        </ol>
        <p className="text-5">How to Enter – Video Competition</p>
        <ol start={7} className="text-5">
          <li>
            The Entrant must submit a video (no more than 2 minutes long) that
            has been uploaded to the Entrant’s public Instagram, TikTok or
            YouTube account. The entrant must include an embeddable link when
            submitting their video to the competition.
          </li>
          <li>
            The Entrant’s video must include the Outback Explorer Competition
            video frames that are available to download from
            www.outbackexplorercompetition.com. It is advised that these video
            frames are added at the start and end of your video.
          </li>
          <li>
            An Entrant may submit more than one entry to enter the Competition,
            but a unique email address must be used for each entry.
          </li>
          <li>
            Entry Period for the Competition is [ 21/02/2023 ] at 12:01am AEST
            to [ 18/04/2023 ] at 11:59pm AEST.
          </li>
          <li>
            When submitting an entry, an Entrant must comply with all applicable
            laws and regulations and must not post, submit or transmit any
            video, information, image, text or other material of any kind
            whatsoever that:
            <ol type="i">
              <li>
                is not original material or material in which the Entrant owns
                copyright, unless the Entrant is authorised by the copyright
                owner to post or transmit that material for the purposes of the
                Competition;
              </li>
              <li>
                violates or infringes upon the rights of any other person,
                including a person’s intellectual property or privacy rights;
              </li>
              <li>
                is unlawful, offensive, threatening, abusive, defamatory,
                derogatory, obscene, vulgar, profane or indecent or that is
                sexual or pornographic in nature;
              </li>
              <li>
                vilifies, insults or humiliates any person on the basis of race,
                religion, ethnicity, gender, age, sexual orientation or any
                physical or mental disability;
              </li>
              <li>
                identifies any person other than the Entrant, unless the Entrant
                has obtained the consent of that person to submit the material
                for the purposes of the Competition and consents to, and submits
                to the application of these Terms and Conditions as if they
                applied to that person as an Entrant;
              </li>
              <li>
                advertises or promotes any goods or services, websites,
                competitions, or schemes or otherwise for commercial purposes;
                and
              </li>
              <li>is knowingly incorrect, misleading, or deceptive.</li>
            </ol>
          </li>
          <li>
            Promotor may, but is under no obligation to, monitor or review the
            contents of the entries and may choose in its sole and unfettered
            discretion to not promote or show any materials (in whole or in
            part) for any reason whatsoever including but not limited to that
            the content is in Promotor’s view in any way objectionable or may
            possibly be in violation of any applicable law or these terms and
            conditions or not promote or adversely effect in any way Promotor or
            a Promotor product or service.
          </li>
          <li>
            The Entrant remains liable for the content of any video,
            information, image, text or other material submitted as an entry and
            indemnifies and holds harmless Promotor against and from any claims
            whatsoever and howsoever arising with respect to the Entrant’s
            entry.
          </li>
          <li>
            Without limiting any other terms herein, the Entrant indemnifies
            Promotor and its directors, officers, employees, and agents
            (“Indemnified Parties”) on a full indemnity basis for all direct and
            consequential loss, causes of actions, demands, damages, fines,
            penalties or other costs or expenses of any kind or nature whether
            monetary or capable of being converted into money, suffered by any
            of the Indemnified Parties, as a result of, or caused or contributed
            to by, any act or omission, including any breach of any of these
            terms and conditions, by the Entrant.
          </li>
        </ol>
        <p className="text-5">Judging</p>
        <ol start={15} className="text-5">
          <li>
            All entries will be judged with one major winner (“Major Prize
            Winner”) and three minor winners (“Minor Winners”) chosen, (together
            referenced as “Winners”).
          </li>
          <li>
            Each valid entry will be assessed by Promotor for creative merit and
            entertainment value.
          </li>
          <li>
            The Major Prize Winner will be determined by Promotor’s panel of
            judges.
          </li>
          <li>
            The Minor Prize winners will be determined by the most votes
            received via the [Outback Explorer Competition web page]
          </li>
          <li>
            The Winners will be notified by email. Judging will take place at
            Promotor’s office in Brisbane, Australia (or at an undisclosed
            location otherwise determined as required by Promotor).
          </li>
          <li>
            This is a game of skill. Chance plays no part in determining the
            Winners. Entries must not have been submitted previously or have
            been submitted to win or in an attempt to win prizes in any other
            competition.
          </li>
          <li>
            The Winners may be published across Promotor websites and social
            media pages following the completed draw and announced via
            Promotor’s websites and social media accounts.
          </li>
        </ol>
        <p className="text-5">Prizes</p>
        <ol start={22} className="text-5">
          <li>
            The total prize pool is valued at approximately $12,115.00 AUD and
            consists of:
            <ol type="i">
              <li>1 x Major Prize of $10,000 AUD cash deposit.</li>
              <li>
                3 x Minor Prizes of an Adventurer Rescue Swag (each valued at
                $160 AUD), plus a GoPro Hero11 Black Mini, each valued at $545
                AUD.
              </li>
            </ol>
          </li>
          <li>Prizes are not transferable or exchangeable.</li>
          <li>
            Payment of the Major Prize will be made by Promoter in Australian
            Dollars. If the Major Prize Winner is not residing in Australia, the
            Major Prize Winner shall be responsible for the cost of any bank
            fees, currency exchange or conversion into their local currency.
            Promotor may refuse to transfer funds to bank accounts, countries or
            persons that are subject to legal restrictions, sanctions, or
            embargoes.
          </li>
          <li>
            Winners will be notified of their win in accordance with the dates
            set out in ‘Important Dates’ below.
          </li>
          <li>
            If you are a winner, you must take part in all publicity,
            photography and other promotional activity as the Promoter requires,
            without any compensation. You consent to the Promoter using your
            name and image in any promotional or advertising activity.
          </li>
        </ol>
        <p className="text-5">Unclaimed Prizes</p>
        <ol start={27} className="text-5">
          <li>
            If a prize is won and unclaimed, that prize will be returned to the
            Promoter and a new winner will be determined by Promotor’s panel of
            judges, from the pool of eligible entrants.
          </li>
        </ol>
        <p className="text-5">Important Dates</p>
        <ol start={28} className="text-5">
          <li>
            First round of judging will occur within 2.5 weeks from competition
            closing date.
          </li>
          <li>
            Winners will be notified via email – they have 7 days to respond
            with their relevant details (e.g. full name, country of residence,
            bank account details (if Major Prize winner), etc).
          </li>
          <li>
            If a Winner does not respond within 7 days with the required
            details, they forfeit any claim to a prize. Another entrant will be
            subsequently chosen and notified via email and will have 7 days to
            respond.
          </li>
          <li>
            Winners will receive their prize within 3 months of having provided
            all the relevant details to Promotor (if not earlier).
          </li>
          <li>
            Public announcement of the Major Prize Winner will occur within 2
            weeks after all relevant details have been submitted to Promotor.
          </li>
          <li>
            Public announcement of Minor Winners will occur within 2 weeks after
            all relevant details have been submitted to Promotor.
          </li>
        </ol>
        <p className="text-5">General Terms and Conditions</p>
        <ol start={34} className="text-5">
          <li>
            Promotor reserves the right, at any time, to verify the validity of
            any entry and Entrant (including an Entrant’s identity and age) and
            to disqualify any Entrant who submits an entry that is not in
            accordance with these Terms and Conditions or who tampers with the
            entry process, including where Promotor reasonably suspects that an
            entry has been submitted using false, incorrect, fraudulent or
            misleading information, including but not limited to personal
            details and contact information or entries that have been submitted
            through the use of multiple identities, email addresses or accounts.
            Incomplete, indecipherable or illegible entries may be deemed
            invalid and errors and omissions will be accepted at Promotor’s
            discretion. Promotor’s failure, or decision not, to enforce any of
            its rights at any stage does not constitute a waiver or variation of
            those rights.
          </li>
          <li>
            Promotor’s decision is final and binding, and the Entrant does not
            have any right to dispute Promotor’s decision or grounds for the
            decision, the Entrant has no right of reply or right to be heard,
            and these Terms and Conditions can be pleaded in bar to any claim or
            proceedings brought by the Entrant in any way relating to the
            Entrant’s entry, Promotor’s decision or anything undertaken,
            submitted or accepted in accordance with, with respect to, or by
            virtue of these Terms and Conditions.
          </li>
          <li>
            Each Entrant consents to and grants royalty free perpetual licence
            to Promotor using the Entrant’s name, video entry, likeness, image
            and voice (including video, film and recording of the same), edit as
            it deems fit and reproduce in any media for an unlimited period for
            the purpose of promoting this Competition (including any outcome) on
            the Promotor website and social media accounts, and promoting any
            products manufactured, distributed or supplied by or related to
            Promotor.
          </li>
          <li>
            If the Competition or voting is interfered with in any way, is not
            capable of being conducted as reasonably anticipated due to any
            reason beyond the reasonable control of Promotor, including as a
            result of mechanical, electrical or electronic tampering or fraud,
            Promotor reserves the right, in its sole discretion, to the fullest
            extent permitted by law either or both of (a) to disqualify any
            Entrant, including any entries submitted by the Entrant; and (b) to
            modify, suspend, terminate or cancel the Competition, as
            appropriate.
          </li>
          <li>
            The Entrant is liable for any cost or liability associated with
            accessing Instagram, TikTok or YouTube or the promotional webpages
            including any liability to any internet service provider used.
          </li>
          <li>
            The use of any automated entry software or any other manual,
            mechanical or electronic means that allows an Entrant to
            automatically repeatedly enter is prohibited and may render all
            entries submitted by the Entrant invalid.
          </li>
          <li>
            The use of any automated voting software or any other manual,
            mechanical or electronic means that allows a vote for an entry to be
            automatically and repeatedly recorded is prohibited and may render
            all entries submitted by an Entrant invalid.
          </li>
          <li>
            Nothing in these Terms and Conditions limits, excludes or modifies
            or purports to limit, exclude or modify the statutory consumer
            guarantees as provided under the Competition and Consumer Act (Cth)
            2010, as well as under any other similar consumer protection laws in
            the State and Territories of Australia (“Non-Excludable
            Guarantees”).
          </li>
          <li>
            Except for any liability that cannot by law be excluded, including
            the Non-Excludable Guarantees, Promotor (including its respective
            officers, employees and agents) is not responsible for and excludes
            all liability (including negligence), for any personal injury; or
            any loss or damage (including loss of opportunity); whether direct,
            indirect, special or consequential, arising in any way out of: (a)
            the Competition; (b) any technical difficulties or equipment
            malfunction (whether or not under the Promotor ‘s control); (c) any
            theft, unauthorised access or third party interference; (d) any
            entry, or prize claim that is late, lost, altered, damaged or
            misdirected (whether or not after their receipt by Promotor) due to
            any reason beyond the reasonable control of Promotor; (e) any
            information, image, text or other material of any kind whatsoever
            submitted as an entry in this Competition (f) any variation in prize
            value to that stated in these Terms and Conditions; (g) any tax
            liability incurred by the Winner or Entrant; or (h) use of the
            prize.
          </li>
          <li>
            Promotor collects personal information in order to conduct the
            Competition and may, for this purpose, disclose such information to
            third parties, including, but not limited to, prize suppliers and as
            required, to Australian regulatory authorities. Entry is conditional
            on providing this information. Promotor may, for an indefinite
            period unless otherwise advised, use an Entrant’s personal
            information for promotional, marketing and publicity purposes
            including sending electronic messages or telephoning the Entrant.
            Each Entrant should direct any request to access, update or correct
            information to Promotor by emailing enquiries@rfds.org.au.
          </li>
          <li>
            The Promoter accepts no responsibility for any tax implications that
            may arise for any prize.
          </li>
          <li>
            This promotion is in no way sponsored by any other entity other than
            Promotor. Promotor can cancel or modify the promotion at its
            discretion.
          </li>
          <li>
            By entering this Competition, you agree and acknowledge that this
            Competition is in no way sponsored, endorsed or administered by, or
            associated with Instagram, TikTok or YouTube.
          </li>
          <li>
            By entering in the Competition, each Entrant agrees that the law of
            Queensland, Australia without regard to conflicts of laws principles
            will apply to all matters relating to this Competition. In the case
            of a dispute, the Entrant agrees to submit to the exclusive
            jurisdiction of the courts of Queensland, Australia, except where,
            by law, such dispute must be brought in the jurisdiction in which
            the Entrant is domiciled, or where the relevant law contains
            mandatory provisions that override such exclusive jurisdiction.
          </li>
        </ol>

        <h5 className="text-4 text-center">
          Win A Rescue Swag Competition Terms & conditions
        </h5>
        <p className="text-5">Terms and Conditions</p>
        <ol className="text-5">
          <li>
            By entering this promotion, you accept these Terms and Conditions.
          </li>
          <li>
            Instructions and information on how to enter this promotion form
            part of these Terms and Conditions.
          </li>
          <li>
            The promotor is RFDS (Qld) Services Limited (ABN 26 161 472 199) of
            12 Casuarina Street, Brisbane Airport QLD 4008 (“Promoter”).
          </li>
        </ol>
        <p className="text-5">How to enter</p>
        <ol start={4} className="text-5">
          <li>
            Entry will be open from [00:00] AEST on [21/02/2023] and closes at
            [23:59] AEST on [18/04/2023] (“Entry Period”). Entries received
            outside of the Entry Period will not be accepted.
          </li>
          <li>
            To enter, you must, during the Entry Period:
            <ol type="a">
              <li>
                Visit the Outback Explorer Competition website and follow the
                prompts to create an account (“Account”); and,
              </li>
              <li>
                Follow the prompts to the vote for your favourite video in the
                “Outback Explorer Competition”.
              </li>
            </ol>
          </li>
          <li>
            You will receive one entry into the promotion by performing the
            activities listed in clause 5. You may not enter the promotion more
            than once.
          </li>
          <li>
            All entries become the property of the Promoter and by entering you
            agree for name and image to be shared across Promotor’s social media
            channels, including Twitter, Facebook, YouTube, TikTok and
            Instagram.
          </li>
        </ol>
        <p className="text-5">Eligible entrants</p>
        <ol start={8} className="text-5">
          <li>
            To be eligible to enter this promotion, you must be:
            <ol type="a">
              <li>
                an Australian resident over the age of 13 years. Entrants under
                the age of 18 years must obtain prior permission of their parent
                or legal guardian. Promotor may verify that permission has been
                given and obtain agreement from parent or guardian to the
                competition terms and conditions. Failure to agree may
                disqualify the Entrant.; and,
              </li>
              <li>the owner of an Account.</li>
            </ol>
          </li>
        </ol>
        <p className="text-5">Draw</p>
        <ol start={9} className="text-5">
          <li>
            There will be one winner determined in respect of this promotion.
          </li>
          <li>
            There will be one draw which will take place at 1pm AEST on
            25/04/2023 at the offices of the Promotor.
          </li>
          <li>
            The first valid entry drawn randomly from the entries received
            during the Entry Period will win the prize. The Promoter may draw
            additional reserve entries in a draw and record them in order, in
            case a winning entry/entrant is deemed invalid or a prize is
            unclaimed (Reserve Entrants)
          </li>
          <li>
            The Promoter may decline to accept any entry which, in its
            reasonable opinion, does not comply with these Terms and Conditions.
          </li>
          <li>
            The Promoter’s decision in relation to any aspect of the promotion
            is final and the Promoter will not enter into any correspondence
            regarding the result of the promotion.
          </li>
        </ol>
        <p className="text-5">Prize and notification of winners</p>
        <ol start={14} className="text-5">
          <li>
            The winner will receive a Rescue Swag Adventurer first aid kit
            valued at $160.
          </li>
          <li>Prizes are not transferable and cannot be taken as cash.</li>
          <li>The winner will be contacted by email on or by 27/04/2023.</li>
          <li>
            To claim the prize, the winner must reply to the email and arrange a
            time to pick up their prize. The prize must be claimed by
            11/05/2023.
          </li>
          <li>
            If a prize has not been accepted or claimed by the prize claim date
            above or if, after making all reasonable attempts, the Promoter
            can’t contact a winner (or a winner does not contact the Promoter)
            by the prize claim date above, the relevant entry will be discarded
            and the Promoter will re-award the relevant prize/s to Reserve
            Entrant/s and/or carry out an unclaimed prize draw at the date, time
            and place stated above to randomly distribute the prize. Any winner
            will be informed in by email within two days of determination.
          </li>
          <li>
            If any prize becomes unavailable for any reason, the Promoter may
            substitute a prize of equal or greater value.
          </li>
          <li>
            The Promoter accepts no responsibility for any tax implications that
            may arise for any prize.
          </li>
          <li>
            If you are a winner, you must take part in all publicity,
            photography and other promotional activity as the Promoter requires,
            without any compensation. You consent to the Promoter using your
            name and image in any promotional or advertising activity.
          </li>
        </ol>
        <p className="text-5">Withdrawal from promotion</p>
        <ol start={22} className="text-5">
          <li>
            You may withdraw from the promotion at any time before notification
            of the winners by notifying the Promoter by email to
            sales@rescueswag.com.au. If you withdraw from the promotion, the
            Promoter will remove your entry and will not use your details or
            entry for media or promotional purposes. If you withdraw from the
            promotion, you will no longer be eligible to win a prize.
          </li>
        </ol>
        <p className="text-5">General terms and conditions</p>
        <ol start={23} className="text-5">
          <li>
            Entries which, in the opinion of the Promoter, are incomplete,
            incorrect or incomprehensible, contain defamatory or offensive
            content or infringe intellectual property rights are not eligible to
            win and may be removed from the promotion and the relevant entrant
            will be disqualified.
          </li>
          <li>
            The Promoter may, in its absolute discretion, disqualify you if, in
            the opinion of the Promoter, you breach these Terms and Conditions,
            engage in dishonest or unethical conduct in relation to the
            promotion, manipulate, tamper or interfere with the conduct of the
            promotion, do not comply with the entry process, or conspire with
            others to gain an unfair advantage. The Promoter may investigate the
            matter if it suspects you of having engaged in such breach or
            conduct.
          </li>
          <li>
            The use of any automated entry software or any other manual,
            mechanical or electronic means that allows you to automatically
            repeatedly enter is prohibited and may render all entries submitted
            by you invalid.
          </li>
          <li>
            The Promoter may request information from you relevant to entry or
            participation in the promotion, such as proof of residency or age.
            The Promoter may, in its absolute discretion, disqualify you if you
            provide insufficient information, false information or fail to
            provide information.
          </li>
          <li>
            The Promoter is not responsible for any late, lost or misdirected
            entries.
          </li>
          <li>
            The Promoter is not responsible for any delays, problems or
            technical malfunction of any telephone network or lines, computer
            systems, computer equipment or software, technical problems or
            traffic congestion on the Internet or at any website, or any
            combination thereof, including any injury or damage to your or any
            other person’s computer related to or resulting from entering this
            promotion. If such problems arise, then the Promoter may modify,
            cancel, terminate or suspend the promotion.
          </li>
          <li>
            To the extent permitted by law, the Promoter is not liable for any
            loss, damage or injury whatsoever (including, but not limited to,
            indirect or consequential loss) resulting from this promotion,
            including the taking of or use of prizes.
          </li>
          <li>
            If, for any reason, this promotion is not capable of running as
            planned, including pandemic, war, terrorism, state of emergency or
            disaster, infection by computer virus, bugs, tampering, unauthorised
            intervention, fraud, technical failures, or any other causes beyond
            the control of the Promoter which corrupt or affect the
            administration, security, fairness, integrity, or proper conduct of
            this promotion, the Promoter reserves the right in its sole
            discretion to cancel, terminate, modify or suspend the promotion.
          </li>
          <li>
            All costs associated with entering the promotion are your
            responsibility.
          </li>
          <li>
            You understand and agree that the Promoter may collect personal
            information from you when you enter the promotion and use it for the
            purpose of running the promotion (which may include disclosure to
            third parties for the purpose of processing and conducting the
            promotion), for promotional purposes surrounding this promotion, as
            well as other purposes, as set out in the Promoter’s Privacy policy
            (located at{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.flyingdoctor.org.au/privacy-policy/"
            >
              https://www.flyingdoctor.org.au/privacy-policy/
            </a>
            ). For further information on how the Promoter deals with your
            personal information, please refer to the Promoter’s Privacy policy.
          </li>
          <li>
            This competition is in no way sponsored, endorsed or administered
            by, or associated with any social media platform, including
            Facebook, Instagram and Twitter. You provide your information to the
            Promoter and not to any social media platform. You completely
            release any relevant social media platforms from any and all
            liability.
          </li>
        </ol> */}
    </>
  )
}

export default TermsAndConditions
