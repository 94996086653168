import PropTypes from 'prop-types'
import { useEffect, useState, useRef } from 'react'

const InstagramEmbedV2 = ({ videoSrc, className }) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(true)
  const [isValid, setIsValid] = useState(true)
  const [isLoaded, setIsLoaded] = useState(false)
  const firstVideoHeight = useRef(null)
  const blockquoteRef = useRef(null)


  // const handleVideoAspectRatio = () => {
  //   const videoContainer = document.querySelector('.instagram-media.instagram-media-rendered')
  //   if (videoContainer) {
  //     const videoHeight = videoContainer.offsetHeight
  //     const newWidth = videoHeight * (13 / 16)
  //     console.log(newWidth)
  //     videoContainer.style.width = `${newWidth}px`
  //   }
  // }

  const handleIframeWidth = () => {
    const videoContainer = document.querySelector('.instagram-media.instagram-media-rendered')
    if (videoContainer) {
      let videoHeight
      if(!firstVideoHeight.current) {
        videoHeight = videoContainer.getAttribute('height')
        if(videoHeight !== '0') firstVideoHeight.current = videoHeight
      }

      const definitiveVideoHeight = firstVideoHeight.current || videoHeight

      // 1
      if(definitiveVideoHeight < 700 && definitiveVideoHeight > 500) {
        const newWidth = definitiveVideoHeight * (1)
        videoContainer.style.width = `${newWidth}px`
      }
      // 1.7
      else if(definitiveVideoHeight < 500) {
        const newWidth = definitiveVideoHeight * (2.4)
        videoContainer.style.width = `${newWidth}px`
      }
      // 10/16
      else {
        const newWidth = definitiveVideoHeight * (10 / 16)
        videoContainer.style.width = `${newWidth}px`
      }

      const timer = setTimeout(() => {
        setIsOverlayVisible(false)
      }, 1000)

      return () => clearTimeout(timer)
    }
  }


  useEffect(() => {
    async function verifyLink() {
      try {
        const response = await fetch(videoSrc, { method: 'HEAD', mode: 'no-cors' })

        if (response.type === 'opaque' || response.ok) {
          setIsValid(true)
        } else {
          setIsValid(false)
          setIsOverlayVisible(false)
        }
      } catch (error) {
        setIsValid(false)
        setIsOverlayVisible(false)
      }
    }

    verifyLink()

    async function loadInstagram() {
      if (typeof document !== 'undefined' && !document.getElementById('igScript')) {
        const igScript = document.createElement('script')
        igScript.setAttribute('src', '//www.instagram.com/embed.js')
        igScript.setAttribute('id', 'igScript')
        igScript.setAttribute('async', '')
        document.head.appendChild(igScript)
      }
    }
    loadInstagram().then(() => {
      if (typeof window.instgrm !== 'undefined') window.instgrm.Embeds.process()
    })
  }, [videoSrc])

  useEffect(() => {
    if (blockquoteRef.current) {
      const observer = new MutationObserver(() => {
        setIsLoaded(true)
      })

      observer.observe(blockquoteRef.current, { childList: true, subtree: true })


      window.addEventListener('resize', handleIframeWidth)

      return () => observer.disconnect()
    }
  }, [blockquoteRef])

  useEffect(() => {
    let interval
    if(!firstVideoHeight.current) {
      interval = setInterval(() => {
        handleIframeWidth()
      }, 1000)
    } else {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [firstVideoHeight])

  return (
    <div
      className={`VideoDetailPage__VideoContainer ${isOverlayVisible ? 'Loading' : ''} ${className}`}
      style={{
        position: 'relative',
        // paddingTop: '60px',
        cursor: 'pointer'
      }}
    >
      {isOverlayVisible && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'black',
          zIndex: 1,
        }} />
      )}
      {isValid ? (
        <blockquote
          ref={blockquoteRef}
          className="instagram-media"
          data-instgrm-permalink={videoSrc}
          data-instgrm-version="14"
          // data-instgrm-width="402"
          // data-instgrm-height="500"
          frameBorder="0"
          style={{
            background: '#FFF',
            border: 0,
            margin: 0,
            padding: 0,
            // height: 'calc(100% + 208px)',
            // width: '402px',
            maxWidth: '100%',
            boxShadow: '0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)',
            position: 'relative',
            overflow: 'hidden',
            zIndex: 0,
            display: isLoaded ? 'block' : 'none',
          }}
        >
          <a href={videoSrc} />
        </blockquote>
      ) : (
        <div className='d-flex text-2 align-items-center justify-content-center text-center' style={{
          color: 'white',
          zIndex: 2,
          boxShadow: '0 0 1px 0 rgba(0,0,0,0.5), 0 1px 10px 0 rgba(0,0,0,0.15)',
          backgroundColor: 'black',
          width: '100%',
          height: '100%',
        }}>
          <p>This video is no longer available or the link is incorrect.</p>
        </div>
      )}
    </div>
  )
}

InstagramEmbedV2.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default InstagramEmbedV2