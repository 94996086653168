import {
  faX,
  faHeart as faSolidHearth,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { AppContext } from '../context'
import { CSSTransition } from 'react-transition-group'
import { useState } from 'react'
import InstagramEmbed from './InstagramEmbed'
import linkDark from '../assets/icons/link-dark.svg'
import facebookIcon from '../assets/icons/facebook.svg'
import twitterIcon from '../assets/icons/twitter.svg'
import whatsappIcon from '../assets/icons/whatsapp.svg'
import { PAGE_URL } from '../constants'
import {
  faHeart,
  faShareFromSquare,
} from '@fortawesome/free-regular-svg-icons'
import { Link } from 'react-router-dom'
import { unvoteVideo, voteVideo } from '../api/videos'
import PropTypes from 'prop-types'

const VideoModal = () => {
  const {
    showVideoModal,
    modalData: { isAdmin, hosted_in, video_link },
    closeVideoModal,
  } = useContext(AppContext)

  const handleCloseModal = () => {
    let prevIgScript = document.getElementById('igScript')
    if (prevIgScript !== null) prevIgScript.remove()
    closeVideoModal()
  }

  const handleVideo = () => {
    switch (hosted_in) {
    case 'Youtube':
      return (
        <div className="ratio ratio-16x9">
          <iframe
            className="embed-responsive-item"
            src={video_link}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
      )
    case 'TikTok':
      return (
        <div
          style={{
            left: 0,
            width: '100%',
            height: 738,
            position: 'relative',
          }}
        >
          <iframe
            style={{
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              position: 'absolute',
              border: 0,
            }}
            className="embed-responsive-item"
            src={video_link}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
      )
    case 'Instagram':
      return <InstagramEmbed videoSrc={video_link} />

    default:
      return <></>
    } }

  return (
    <Modal
      show={showVideoModal}
      onHide={handleCloseModal}
      centered
      dialogClassName={hosted_in}
      className="p-0"
    >
      <Modal.Body className={'ModalVideo__Container'}>
        <div className={'ModalVideo'}>
          <div className='ModalVideo__Video_Container'>
            <div className='ModalVideo__Video'>
              {handleVideo()}
            </div>
          </div>
          {isAdmin ? <AdminInfo /> : <UserInfo />}
        </div>
      </Modal.Body>
      <div className="ModalVideo__Close" onClick={handleCloseModal}>
        <FontAwesomeIcon icon={faX} size="xl" style={{ height: 20 }} />
      </div>
    </Modal>
  )
}

export default VideoModal

export const AdminInfo = () => {
  const {
    modalData: {
      name,
      description,
      user_name,
      user_last_name,
      user_email,
      user_age,
      user_phone,
      user_country,
      user_city,
      votes,
    },
  } = useContext(AppContext)
  return (
    <div className="ModalVideo__info">
      <div className="ModalVideo__info_item" style={{overflowY: 'auto'}}>
        <p className="ModalVideo__Name">{name}</p>
        <p className='ModalVideo__Description'>{description}</p>

      </div>
      <div className="ModalVideo__info_item bg-light mb-3" style={{minHeight: 'max-content'}}>
        <p className="ModalVideo__UserName mb-3">
          {user_name} {user_last_name}
        </p>
        <Row className='mb-2'>
          <p className="admin-label">Email</p>
          <p className="admin-field">{user_email}</p>
        </Row>
        <Row className='mb-2'>
          <Col>
            <p className="admin-label">Age</p>
            <p className="admin-field">{user_age}</p>
          </Col>
          <Col>
            <p className="admin-label">Mobile</p>
            <p className="admin-field">{user_phone}</p>
          </Col>
        </Row>
        <Row className='mb-2'>
          <Col>
            <p className="admin-label">Country</p>
            <p className="admin-field">{user_country}</p>
          </Col>
          <Col>
            <p className="admin-label">City</p>
            <p className="admin-field">{user_city}</p>
          </Col>
        </Row>
        <Row className='mb-2'>
          <Col>
            <p className="admin-label">Likes</p>
            <p className="admin-field">{votes}</p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export const UserInfo = () => {
  const {
    modalData: { name, description, user_name, user_last_name, hideVotes, isExample },
    competitionInfo,
  } = useContext(AppContext)

  const [showPopup, setShowPopup] = useState(false)

  const openPopup = () => setShowPopup(true)
  const closePopup = () => setShowPopup(false)

  return (
    <>
      <div className="ModalVideo__info_User">
        <div className="ModalVideo__info_item">
          <h3 className="mb-4 mt-2">{name}</h3>
          <h5 className="mb-4">
            {user_name} {user_last_name}
          </h5>
          <p style={{ fontSize: '17px' }}>{description}</p>
        </div>
        {!isExample && (
          <div
            className={`ModalVideo__info_item_Special ${competitionInfo && competitionInfo.state === 'finished' || competitionInfo && competitionInfo.state !== 'ongoing'
              ? 'justify-content-end'
              : ''}`}
          >
            {competitionInfo && competitionInfo.state === 'ongoing' && !hideVotes && (
              <VoteVideo
                showPopup={showPopup}
                openPopup={openPopup}
                closePopup={closePopup}
              />
            )}
            <ShareVideo />
          </div>

        )}
        <div
          className={`ModalVideo__info_item_Special_Background ${showPopup ? 'Active' : ''}`}
          onClick={closePopup}
        />
      </div>
    </>
  )
}

export const VoteVideo = ({ showPopup, openPopup, closePopup }) => {
  const {
    token,
    unregisteredUserId,
    setUnregisteredUserId,
    modalData: { id, user_votes_today = 0, refetch },
    remainingVotes,
    refreshRemainingVotes,
    openVideoModal,
  } = useContext(AppContext)

  const [voted, setVoted] = useState(false)
  const [liked, setLiked] = useState(true)
  const [userVotesToday, setUserVotesToday] = useState(0)
  const [amountLikes, setAmountLikes] = useState(1)
  const [disableLike, setDisableLike] = useState(false)

  useEffect(() => {
    setAmountLikes(user_votes_today !== 0 ? user_votes_today : 1)
    setLiked(user_votes_today !== 0)
    setUserVotesToday(user_votes_today)
  }, [user_votes_today])

  const setLikeVideo = async () => {
    if (disableLike) return null
    setDisableLike(true)
    if (token) {
      setVoted(true)
      setLiked(amountLikes !== 0)
      setUserVotesToday(amountLikes)
      if (amountLikes === 0) closePopup()
    } else if (!liked) {
      setLiked((p) => !p)
      openPopup()
      setVoted(true)
    } else {
      closePopup()
      setLiked((p) => !p)
      setVoted(true)
    }

    let body = {
      votes_amount: token ? amountLikes : 1,
    }
    if (!token) {
      body.unregistered_user_id = unregisteredUserId
    }
    let response = null

    if (token || !liked) response = await voteVideo(id, token, body)
    else response = await unvoteVideo(id, token, body)
    if (!response) return setDisableLike(false)
    const { data, ok } = response
    if (!ok) return setDisableLike(false)

    if (data.data) {
      refreshRemainingVotes()
      openVideoModal({ ...data.data, refetch })
    }

    if (data.unregistered_user_id)
      setUnregisteredUserId(data.unregistered_user_id)
    refetch()

    setDisableLike(false)
  }

  const handleMinusVote = () => {
    if (amountLikes === (user_votes_today === 0 ? 1 : 0)) return null
    setAmountLikes((prev) => prev - 1)
  }

  const handlePlusVote = () => {
    if (amountLikes >= remainingVotes + user_votes_today) return null
    setAmountLikes((prev) => prev + 1)
  }

  return (
    <div className="ModalVideo__info_item_Special_Item vote d-flex justify-content-center align-items-center gap-2 cursor-pointer">
      <CSSTransition in={showPopup} timeout={4000} unmountOnExit>
        <button className="VotePopup" onBlur={closePopup}>
          <div
            className="float-end text-end cursor-pointer"
            onClick={closePopup}
          >
            <FontAwesomeIcon icon={faX} size="xl" style={{ color: 'black' }} />
          </div>
          {remainingVotes === 0 && user_votes_today === 0 ? (
            token ? (
              <>
                <p className="text-4 text_primary">
                  You have used all your votes for today.
                </p>
                <p className="text-4 text_primary">
                  Remember to return again tomorrow to vote for this video!
                </p>
              </>
            ) : (
              <>
                <p className="text-4 text_primary">
                  You have used all your votes for today.
                </p>
                <p className="text-4 text_primary">
                  Create an account and get <strong>five votes per day</strong>,
                  plus the chance to win a Rescue Swag!
                </p>
                <div className="d-grid gap-2 mt-auto">
                  <Link to="/register" className="w-100">
                    <div className="w-100 px-5 btn btn-rs" onClick={closePopup}>
                      Create account
                    </div>
                  </Link>
                </div>
              </>
            )
          ) : token ? (
            !voted ? (
              <>
                <h3 className="mb-4 text_primary">Confirm votes</h3>
                <p className="text-4 text_primary">
                  How many votes would you like to cast for this video?
                </p>
                <div className="d-flex justify-content-center align-items-center gap-4 mb-4">
                  <FontAwesomeIcon
                    icon={faMinus}
                    size="xl"
                    className="cursor-pointer text-select-none text_primary"
                    onClick={handleMinusVote}
                    style={{ color: 'black' }}
                  />
                  <div className="d-flex flex-column align-items-center">
                    <p className="mb-0 text-1 fw-bold text_primary">{amountLikes}</p>
                    <p className="mb-0 fw-500 text_primary">Vote</p>
                  </div>
                  <FontAwesomeIcon
                    icon={faPlus}
                    size="xl"
                    className="cursor-pointer text-select-none"
                    onClick={handlePlusVote}
                    style={{ color: 'black' }}
                  />
                </div>
                <div className="w-100 px-5 btn btn-rs text_primary" onClick={setLikeVideo}>
                  Confirm votes
                </div>
              </>
            ) : (
              <>
                <h3 className="mb-4 text_primary">Vote successful</h3>
                {remainingVotes === 0 ? (
                  <p className="text-4 text_primary">
                    You have used all your votes for today.
                  </p>
                ) : (
                  <></>
                )}
                <p className="text-4 text_primary">
                  Remember to return again tomorrow to vote for this video
                  again!
                </p>
              </>
            )
          ) : liked ? (
            !voted ? (
              <>
                <p className="text-4 text_primary">
                  Are you sure you want to remove your vote for this video?
                </p>
                <div className="d-grid gap-2 mt-auto">
                  <div className="w-100 px-5 btn btn-rs" onClick={setLikeVideo}>
                    Yes, remove vote
                  </div>
                  <div
                    className="w-100 px-5 btn btn-rs-dark"
                    onClick={closePopup}
                  >
                    No
                  </div>
                </div>
              </>
            ) : (
              <>
                <h3 className="mb-4 text_primary">Vote successful</h3>
                <p className="text-4 fw-bold text_primary">Want to cast more votes now?</p>
                <p className="text-4 text_primary">
                  Create an account and get <strong>five votes per day</strong>,
                  plus the chance to win a Rescue Swag!
                </p>
                <div className="d-grid gap-2 mt-auto">
                  <Link to="/register" className="w-100">
                    <div className="w-100 px-5 btn btn-rs" onClick={closePopup}>
                      Create account
                    </div>
                  </Link>
                </div>
              </>
            )
          ) : (
            <>
              {/* Prevent Move */}
              <p className="text-4 text_primary">
                Are you sure you want to remove your vote for this video?
              </p>
              <div className="d-grid gap-2 mt-auto">
                <div className="w-100 px-5 btn btn-rs" onClick={setLikeVideo}>
                  Yes, remove vote
                </div>
                <div
                  className="w-100 px-5 btn btn-rs-dark"
                  onClick={closePopup}
                >
                  No
                </div>
              </div>
            </>
          )}
        </button>
      </CSSTransition>
      <div
        className="ModalVideo__info_item_Special_Item_Button d-flex justify-content-center align-items-center gap-2 cursor-pointer"
        onClick={() => {
          if (remainingVotes !== 0 && !token && !liked) return setLikeVideo()
          openPopup(), setVoted(false)
        }}
      >
        {liked ? (
          <>
            <FontAwesomeIcon
              icon={faSolidHearth}
              color="red"
              size="2xl"
              className="LikeAnimation"
              beatFade
            />
            <p className="mb-0 text-2 fw-bold">
              {token ? `${userVotesToday} vote${userVotesToday !== 1 ? 's' : ''} cast` : 'Video voted'}
            </p>
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faHeart} size="2xl" />
            <p className="mb-0 text-2 fw-bold">Vote for this video</p>
          </>
        )}
      </div>
    </div>
  )
}

export const ShareVideo = () => {
  const {
    showVideoModal,
    modalData: { id },
  } = useContext(AppContext)

  const [showShare, setShowShare] = useState(false)
  const [showSuccessClipboard, setShowSuccessClipboard] = useState(false)

  useEffect(() => {
    if (!showVideoModal) setShowShare(false)
  }, [showVideoModal])

  const videoURL = `${PAGE_URL}/?video_id=${id}`

  const socialMediaList = [
    {
      icon: facebookIcon,
      name: 'Facebook',
      link: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        videoURL
      )}`,
    },
    {
      icon: twitterIcon,
      name: 'Twitter',
      link: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        videoURL
      )}`,
    },
    {
      icon: whatsappIcon,
      name: 'Whatsapp',
      link: `https://api.whatsapp.com/send?text=${encodeURIComponent(
        videoURL
      )}`,
    },
  ]

  const toggleShowShare = () => setShowShare((prev) => !prev)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(videoURL).then(() => {
      setShowSuccessClipboard(true)
      setTimeout(() => setShowSuccessClipboard(false), 2000)
    })
  }

  return (
    <>
      <div className="ModalVideo__info_item_Special_Item d-flex flex-column justify-content-center align-items-center">
        <CSSTransition in={showShare} timeout={400} unmountOnExit>
          <button className="Share" onBlur={toggleShowShare}>
            <p className="text-4 fw-500 text-start mb-2 ms-2 ms-lg-0">
              Share video
            </p>
            <div className="d-flex justify-content-between gap-2">
              <div
                className="d-flex flex-column align-items-center gap-2 cursor-pointer"
                onClick={copyToClipboard}
              >
                <div className="Share__Item_Icon">
                  <img src={linkDark} width={25} height={25} alt="link icon" />
                </div>
                <p className="mb-0 text-5 fw-500 text-nowrap">Copy link</p>
              </div>
              {socialMediaList.map((socialMedia, index) => (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={socialMedia.link}
                  className="d-flex flex-column align-items-center gap-2 cursor-pointer"
                  key={index}
                >
                  <div className="Share__Item_Icon">
                    <img
                      src={socialMedia.icon}
                      width={25}
                      height={25}
                      alt="link icon"
                    />
                  </div>
                  <p className="mb-0 text-5 fw-500 text-nowrap">
                    {socialMedia.name}
                  </p>
                </a>
              ))}
            </div>
          </button>
        </CSSTransition>
        <div
          className="ModalVideo__info_item_Special_Item_Button d-flex flex-row-reverse flex-lg-row justify-content-center align-items-center gap-2 cursor-pointer"
          onClick={toggleShowShare}
        >
          <p className="mb-0 text-2 fw-bold">Share video</p>
          <FontAwesomeIcon icon={faShareFromSquare} size="lg" />
        </div>
      </div>
      <CSSTransition in={showSuccessClipboard} timeout={200} unmountOnExit>
        <div className="clipboard">
          <p className="mb-0 text-4 fw-500">Link copied to clipboard</p>
        </div>
      </CSSTransition>
    </>
  )
}

VoteVideo.propTypes = {
  showPopup: PropTypes.bool.isRequired,
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
}
