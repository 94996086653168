import { useEffect, useContext } from 'react'
import { useLocation, Outlet } from 'react-router'
import Header from '../containers/Header'
import Footer from '../containers/Footer'
import VideoModal from '../components/VideoModal'
import { AppContext } from '../context'
import { useMediaQuery } from '@uidotdev/usehooks'

const Layout = () => {
  const location = useLocation()
  const { setPreviousPath } = useContext(AppContext)

  const isMobile = useMediaQuery('(max-width: 576px)')

  useEffect(() => {
    if (!location.hash) return document.querySelector(isMobile ? 'html' : 'body').scrollTo(0, 0)
    const element = document.getElementById(location.hash.slice(1))
    element?.scrollIntoView({ behavior: 'smooth' })
  }, [location])

  useEffect(() => {
    return () => {
      setPreviousPath(location.pathname)
    }
  }, [location])

  return (
    <>
      <Header />
      <div className="content">
        <Outlet />
        <div className='ContentLayoutSeparation'/>
        <Footer />
      </div>
      <VideoModal />
    </>
  )
}

export default Layout