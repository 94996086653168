import Container from 'react-bootstrap/Container'
import { Col, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import CompetitionLogo from '../assets/landing/homeLogo.webp'
import { AppContext } from '../context'
import { useContext, useMemo } from 'react'
import facebookIcon from '../assets/icons/facebook-yellow.svg'
import instagramIcon from '../assets/icons/instagram-yellow.svg'
import youtubeIcon from '../assets/icons/youtube-yellow.svg'
import { getTodaysDay } from '../api/getDate'
import { useQuery } from 'react-query'

const Footer = () => {
  const { competitionInfo, timelineCompetitions } = useContext(AppContext)
  const navigate = useNavigate()
  const location = useLocation()

  const { data: todaysDateData } = useQuery(
    ['getTodaysDay'],
    () => getTodaysDay(),
    {
      refetchOnWindowFocus: false,
    }
  )

  const currentYear = useMemo(() => {
    if (todaysDateData && todaysDateData.today) {
      return new Date(todaysDateData.today).getFullYear()
    }
    return new Date().getFullYear()
  }, [todaysDateData])

  const handleInternalNavLinkClick = (hash) => {
    if (location.pathname === '/') {
      document.querySelector(hash).scrollIntoView({ behavior: 'smooth' })
    } else {
      navigate(`/${hash}`)
    }
  }

  return (
    <footer className="Footer">
      <Container>
        <Row className="py-5">
          <Col
            sm={12}
            md={4}
            className="d-flex flex-column justify-content-start justify-content-center align-items-center align-items-md-start text-center mb-5 mb-md-0  gap-4"
          >
            <div className='d-flex flex-column gap-4 ' style={{ width: 'max-content'}}>

              {competitionInfo && competitionInfo.state === 'ongoing' && (
                <>
                  <Button id="Footer-Enter-Btn" onClick={() => handleInternalNavLinkClick('#how-to-enter')} variant="rs-secondary">
                    How to enter
                  </Button>
                  <Button id="Footer-Enter-Btn" onClick={() => handleInternalNavLinkClick('#enter-competition')} variant="rs">
                    Submit video
                  </Button>
                </>
              )}

              <div className="d-flex justify-content-between align-items-center gap-4 ">
                <a
                  href="https://www.facebook.com/RFDSQld/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebookIcon} alt="Facebook icon" />
                </a>
                <a
                  href="https://www.instagram.com/rfdsqld/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagramIcon} alt="Facebook icon" style={{ color: 'white' }} />
                </a>
                <a
                  href="https://www.youtube.com/@RoyalFlyingDoctor"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={youtubeIcon} alt="Facebook icon" />
                </a>
              </div>
            </div>
          </Col>
          <Col sm={12} md={4} className="d-flex align-items-start justify-content-center">
            <img
              src={CompetitionLogo}
              className="mb-5"
              alt="Competition logo"
              width={166}
            />
          </Col>
          <Col sm={12} md={4} className="d-flex text-center align-items-center justify-content-center justify-content-md-end">
            <ul className="nav d-flex flex-column h-100 align-items-center justify-content-start gap-3">
              {competitionInfo && competitionInfo.state !== 'nearFuture' ? (
                <li className="nav-item">
                  <Link to="/all-videos/">Watch & vote</Link>
                </li>
              ) : (
                <></>
              )}
              {timelineCompetitions.lastCompetitions && timelineCompetitions.lastCompetitions.length !== 0 && (
                <li className="nav-item">
                  <Link to={`/winners/${timelineCompetitions.lastCompetitions[0].id}`}>Past winners</Link>
                </li>
              )}
              <li className="nav-item">
                <Link to="/terms&conditions">Terms & conditions</Link>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.flyingdoctor.org.au/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy policy
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Row className="w-100 me-0 ms-0" style={{ backgroundColor: '#C5351C' }}>
        <Col className="text-center text-5 py-2 fw-500 border-top border-secondary BottomNav">
          © Royal Flying Doctor Service (Queensland Section) {currentYear}
        </Col>
      </Row>
      <Row className="w-100 me-0 ms-0">
        <Col className="text-6 text-center py-2 Linkchar__Text">
          Powered by{' '}
          <a
            href="https://linkchar.com/"
            target="_blank"
            rel="noreferrer"
          >
            Linkchar
          </a>
        </Col>
      </Row>
    </footer>
  )
}

export default Footer
