import Logo from '../assets/landing/BigLogo.webp'
import ADV from '../assets/landing/Noimage.png'
import EXP from '../assets/landing/Noimage.png'
import YoutubeEmbed from '../components/YoutubeEmbed'
import { Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const WhoWeAre = () => {
  return (
    <>
      <Helmet>
        <title>Who we are | RFDS (Queensland Section) + Elders</title>
      </Helmet>
      <section className="WhoWeAre">
        <div className='d-flex w-100 align-items-center justify-content-center'>

          <img
            src={Logo}
            alt="Rescue Swag and Royal Flying Doctor Service logo"
            className="WhoWeAre__Logo"
          />
        </div>

        <div className="WhoWeAre__Texts">
          <p>
            With a combined 280 years of expertise, RFDS and Elders have a shared history of being in service of
            Queenslanders, enabling them to take risks and push the boundaries of geography, commerce and
            community.
          </p>
          <p>
            This year, Elders and RFDS have joined forces through a three-year community partnership to deepen
            both organisations’ connection to the communities we serve and to bring to life the aspirations of the
            next generation of regional Queenslanders, so they too can enjoy full and meaningful lives in the bush.
          </p>
          <p>
            As part of our community partnership, RFDS and Elders are excited to launch the 2024 Outback Explorer
            Competition, to showcase the richness and diversity of Queensland communities, and the people who
            live within them.
          </p>
          <p className="fw-bold">
            About the RFDS
          </p>
          <p>
            The Royal Flying Doctor Service provides excellence in, and access to primary health care and
            aeromedical services across the state. Our emergency retrieval service operates 24 hours a day, seven
            days a week, delivering the finest care to more than 330,000 Australians each year — that’s one person
            every two minutes. In addition to aeromedical retrievals of the critically ill or injured, the RFDS also
            delivers a broad range of essential primary and preventative healthcare services, including telehealth,
            mental health, oral health and health promotion.
          </p>
          <p className="ffam-FreeSans fw-bold">
            About Elders
          </p>
          <p>
            As a trusted Australian agribusiness, Elders has played an important role in the agricultural sector for
            185 years. Elders’ expansive network across Australia offers links to markets, tailored advice and
            specialist knowledge across a range of products and services, including farm supplies, agronomy,
            livestock, wool, grain, finance, insurance, and real estate.
          </p>
          <p>
            Elders is committed to a sustainable and prosperous future for rural and regional communities, and
            creating value for all stakeholders in the agricultural supply chain, across both Australian and
            international markets.
          </p>
        </div>

        {/* <div className="WhoWeAre__Images_Container">
          <img
            src={ADV}
            alt={'Rescue Swag kit'}
            className="WhoWeAre__Images_Item"
          />
          <img
            src={EXP}
            alt={'Rescue Swag kit'}
            className="WhoWeAre__Images_Item"
          />
        </div>

        <div className="WhoWeAre__Buttons d-flex flex-column gap-4">
          <Button variant={'rs w-100'}>Action</Button>
        </div> */}

        <YoutubeEmbed video="https://www.youtube.com/embed/zq-Dvr2k74Y?si=1oA8GTFJmyje1RSQ" className="HowToEnter__Video" />
      </section>
    </>
  )
}

export default WhoWeAre
