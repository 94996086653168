import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import logo from '../assets/landing/navbarLogo.webp'
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useContext, useState } from 'react'
import { AppContext } from '../context'
import { Button, Nav } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { getCompetitions } from '../api/competitions'

const AuthenticatedHeader = () => {
  const { token, setToken } = useContext(AppContext)
  const navigate = useNavigate()
  const [ShowCollapse, setShowCollapse] = useState(false)

  const handleLogout = async () => {
    navigate('/', { replace: true })
    setToken(null)
  }

  const toggleShowCollapse = () => setShowCollapse((prev) => !prev)

  const { data } = useQuery(
    ['getCompetitions', token],
    () => getCompetitions(token),
    {
      initialData: {
        data: {
          competitions: [],
          current_page: 1,
          total_pages: 1,
        },
        ok: false,
      },
      refetchOnWindowFocus: false
    }
  )
  return (
    <>
      <Navbar
        expanded={ShowCollapse}
        expand="lg"
        bg="white"
        variant="light"
        fixed="top"
      >
        <Container fluid>
          <Link to="/" className="navbar-brand">
            <img
              src={logo}
              width={240}
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Link>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={toggleShowCollapse}
          />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="nav-rs py-3 py-lg-0">
              <NavLink
                className="d-block d-lg-none"
                activeclassname="active"
                to="admin-videos"
                onClick={toggleShowCollapse}
              >
                Videos
              </NavLink>
              <NavLink
                className="d-block d-lg-none"
                activeclassname="active"
                to="admin-ranking"
                onClick={toggleShowCollapse}
              >
                Ranking
              </NavLink>
              <NavLink
                className="d-block d-lg-none"
                activeclassname="active"
                to="admin-users"
                onClick={toggleShowCollapse}
              >
                Users
              </NavLink>
              <div className="d-block d-lg-none accordion " id="accordionPanel">
                <div className="accordion-item ">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    Winners
                    <span className="arrow-icon"></span>
                  </button>
                  <div
                    id="panelsStayOpen-collapseOne"
                    className="accordion-collapse collapse py-2 overflow-y-auto"
                    style={{maxHeight: 150}}
                    data-bs-parent="#accordionPanelsStayOpenExample"
                  >
                    <li className='py-2'>
                      <NavLink activeclassname="active" to="/new-competition" className='p-3'>New competition</NavLink>
                    </li>
                    {data.ok && data.data.competitions.length !== 0 && (
                      data.data.competitions.sort((a, b) =>
                        new Date(b.start_date) - new Date(a.start_date))
                        .map((competition) => (
                          <li className='py-2' key={competition.id}>
                            <NavLink activeclassname="active" className='p-3' to={`/admin-winners/${competition.id}`}>{competition.name}</NavLink>
                          </li>
                        ))
                    )}
                  </div>
                </div>
              </div>
              <Button
                variant="rs-secondary"
                onClick={handleLogout}
              >
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
                <span className="ms-2">Log out</span>
              </Button>
              {/* <Button
                variant="rs-white-yellow d-lg-none"
                onClick={handleLogout}
              >
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
                <span className="ms-2">Log out</span>
              </Button> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default AuthenticatedHeader
