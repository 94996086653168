import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { useContext } from 'react'
import { AppContext } from '../context'
import PropTypes from 'prop-types'

const MobileVotes = ({ isNavbarCollapsed }) => {
  const [showText, setShowText] = useState(false)
  const { remainingVotes } = useContext(AppContext)

  const handleMouseEnter = () => {
    setShowText(true)
  }

  const handleMouseLeave = () => {
    setShowText(false)
  }

  const handleClick = () => {
    if (isNavbarCollapsed) {
      setShowText(!showText)
    }
  }

  return (
    <div
      className="Header__MobileVotes"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      style={{ pointerEvents: isNavbarCollapsed ? 'auto' : 'none' }}
    >
      <div className="Header__votes_Heart" style={{ marginRight: '12px' }}>
        <FontAwesomeIcon icon={faHeart} />
        <p className="Header__votes_Heart_Number mb-0">
          {remainingVotes}
        </p>
      </div>
      {showText && (
        <div className={`Header__MobileVotes__Text ${!isNavbarCollapsed ? 'collapsed' : ''}`}>
          <div className="Header__votes_Heart" style={{ marginRight: '12px' }}>
            <FontAwesomeIcon icon={faHeart} />
            <p className="Header__votes_Heart_Number mb-0">
              {remainingVotes}
            </p>
          </div>
          Votes remaining today
        </div>
      )}
    </div>
  )
}

export default MobileVotes

MobileVotes.propTypes = {
  isNavbarCollapsed: PropTypes.bool.isRequired,

}