import { Card, Col, Row } from 'react-bootstrap'
import playIcon from '../assets/landing/playIcon.png'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { AppContext } from '../context'
import * as constants from '../constants'

const AdminWinnerVideos = ({ position, video, image }) => {
  const { openVideoModal } = useContext(AppContext)

  return (
    <div className="AdminWinnerVideos mb-4">
      <div className="d-flex justify-content-center align-items-center h-100" style={{ backgroundColor: '#F9F9F9' }}>
        <h3>#{position}</h3>
      </div>
      <div className="AdminVideoRanking__Video_Container">
        <Card className="video-card crop w-100 h-100">
          <Card.Img src={`${image?.indexOf('http://') === 0 || image?.indexOf('https://') === 0 ? '' : constants.URL}${image}`} alt="Card image" className="h-100" />
          <Card.ImgOverlay className="text-white h-100 d-flex flex-column justify-content-end">
            <img src={playIcon} className="play-icon cursor-pointer" alt="" onClick={() => openVideoModal({ ...video, isAdmin: true })} />
          </Card.ImgOverlay>
        </Card>
      </div>
      <div className="AdminWinnerVideos__Description p-4 overflow-y-auto">
        <h5 className="mb-3">{video.name}</h5>
        <p className='ffam-FreeSans'>{video.user_name} {video.user_last_name}</p>
        <Row>
          <Col>
            <p className="admin-label">Description</p>
            <p className="admin-field">{video.description}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="admin-label">Country</p>
            <p className="admin-field">{video.user_country}</p>
          </Col>
          <Col>
            <p className="admin-label">City</p>
            <p className="admin-field">{video.user_city}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="admin-label">Email</p>
            <p className="admin-field">{video.user_email}</p>
          </Col>
          <Col>
            <p className="admin-label">Votes</p>
            <p className="admin-field">{video.votes}</p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AdminWinnerVideos

AdminWinnerVideos.propTypes = {
  video: PropTypes.object.isRequired,
  position: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
}
