import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import logo from '../assets/landing/navbarLogo.webp'
import Button from 'react-bootstrap/Button'
import { useContext, useEffect, useState, useRef } from 'react'
import { AppContext } from '../context'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logoutIcon from '../assets/icons/logoutIcon.svg'
import {
  faArrowRightFromBracket,
  faHeart,
} from '@fortawesome/free-solid-svg-icons'
import { Modal } from 'react-bootstrap'
import NavDropdown from '../components/NavDropdown'
import { getCompetitions } from '../api/competitions'
import { useQuery } from 'react-query'
import VotesRemaining from '../components/VotesRemaining'
import MobileVotes from '../components/MobileVotes'
import PropTypes from 'prop-types'
import useOnClickOutside from '../hooks/useOnClickOutside'

const Header = ({ isAuth }) => {
  const {
    token,
    setToken,
    competitionInfo,
    remainingVotes,
    // timelineCompetitions
  } = useContext(AppContext)
  const [showLogout, setShowLogout] = useState(false)
  const [ShowCollapse, setShowCollapse] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [activeLink, setActiveLink] = useState('')
  const [isMobile, setIsMobile] = useState(false)

  const refMenuMobile = useRef()

  useOnClickOutside(refMenuMobile, () => {
    setShowCollapse(false)
  })

  const { data: competitionsData } = useQuery(
    ['getCompetitions'],
    () => getCompetitions(),
    {
      initialData: {
        data: {
          competitions: [],
          current_page: 1,
          total_pages: 1,
        },
        ok: false,
      },
      refetchOnWindowFocus: false
    }
  )
  
  useEffect(() => {
    const checkIsMobile = () => {
      if (window.matchMedia('(max-width: 1199px)').matches) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
    checkIsMobile()

    window.addEventListener('resize', checkIsMobile)

    return () => window.removeEventListener('resize', checkIsMobile)
  })

  const openShowLogout = () => {
    setShowLogout(true)
  }

  const closeShowLogout = () => {
    setShowLogout(false)
  }

  const handleLogout = async () => {
    navigate('/', { replace: true })
    setToken(null)
    setShowLogout(false)
  }

  useEffect(() => {
    setActiveLink(location.hash)
  }, [location])

  const toggleShowCollapse = () =>
    isMobile ? setShowCollapse((prev) => !prev) : null

  const handleNavLinkClick = (hash) => {
    setActiveLink(hash)
    toggleShowCollapse()
  }

  const handleInternalNavLinkClick = (hash) => {
    if (location.pathname === '/') {
      document.querySelector(hash).scrollIntoView({ behavior: 'smooth' })
    } else {
      navigate(`/${hash}`)
    }
    handleNavLinkClick(hash)
  }

  const handleNavLinkRedirect = (path) => {
    navigate(path)
    setShowCollapse(false)
  }


  return (
    <>
      <div className="w-100 position-fixed top-0 z-index-fixed">
        <div className="TopNavbar">
          <Link
            to='/who-we-are'
            rel="noreferrer"
            className='text-center'
          >
            BROUGHT TO YOU BY THE ROYAL FLYING DOCTOR SERVICE (QUEENSLAND SECTION) & ELDERS
          </Link>
        </div>
        <Navbar expanded={ShowCollapse} expand="xl" bg="white" variant="light" ref={refMenuMobile}>
          <Container>
            <Link to="/" className="navbar-brand">
              <img
                src={logo}
                width={240}
                className="d-inline-block align-top custom-logo"
                alt="React Bootstrap logo"
              />
            </Link>
            <div className='ContainerHamLogo'>
              {!isAuth && competitionInfo && competitionInfo.state === 'ongoing' && (
                <MobileVotes isNavbarCollapsed={!ShowCollapse} />
              )}
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                onClick={toggleShowCollapse}
              />
            </div>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-between navbar-collapse-scroll"
            >
              <Nav className="nav-rs py-lg-0 align-items-center justify-content-center">
                {competitionInfo && competitionInfo.state === 'ongoing' && (
                  <>
                    {!isAuth && (
                      <>
                        <Nav.Link className='d-flex d-xl-none align-items-center'>
                          <div className="Header__votes_Heart" style={{ marginRight: '12px' }}>
                            <FontAwesomeIcon icon={faHeart} />
                            <p className="Header__votes_Heart_Number mb-0">
                              {remainingVotes}
                            </p>
                          </div>
                          Votes remaining today
                        </Nav.Link>
                        <div className='DivisoryLine' />
                      </>
                    )}
                    <Nav.Link
                      className={`nav-link ${activeLink === '#how-to-enter' ? 'active' : ''}`}
                      onClick={() => handleInternalNavLinkClick('#how-to-enter')}
                    >
                      How to enter
                    </Nav.Link>
                    <Nav.Link
                      className={`nav-link ${activeLink === '#enter-competition' ? 'active' : ''}`}
                      onClick={() => handleInternalNavLinkClick('#enter-competition')}
                    >
                      Submit video
                    </Nav.Link>
                  </>
                )}
                <NavLink
                  to="/who-we-are"
                  className="nav-link"
                  onClick={() => handleNavLinkClick('#who-we-are')}
                >
                  Who we are
                </NavLink>

                {competitionInfo && competitionInfo.state === 'finished' && (
                  <>
                    <NavDropdown />
                    <NavLink
                      to={`/winners/${competitionInfo.competition.id}`}
                      className="nav-link"
                    >
                      Competition winners
                    </NavLink>
                  </>
                )}

                {competitionInfo && competitionInfo.state === 'ongoing' && (
                  <>
                    <NavLink
                      to="/all-videos"
                      className="nav-link"
                      onClick={() => handleNavLinkRedirect('/all-videos')}
                    >
                      Watch & vote
                    </NavLink>
                    <NavLink
                      to="/inspiration"
                      className="nav-link"
                      onClick={() => handleNavLinkRedirect('/inspiration')}
                    >
                      Inspiration
                    </NavLink>
                    <NavLink
                      to="/faqs"
                      className="nav-link"
                      onClick={() => handleNavLinkRedirect('/faqs')}
                    >
                      FAQs
                    </NavLink>
                  </>
                )}
              </Nav>
              <section className='d-flex flex-column flex-xl-row gap-2 justify-content-center align-items-center mt-xl-0'>
                {competitionInfo && competitionInfo.state === 'ongoing' && (
                  <div className='d-flex justify-content-center align-items-center'>
                    {!isAuth && (
                      <div className="Header__votes d-xl-flex HeartDesktop">
                        <div className="Header__votes_Heart">
                          <FontAwesomeIcon icon={faHeart} />
                          <p className="Header__votes_Heart_Number mb-0">
                            {remainingVotes}
                          </p>
                        </div>
                        <p className="Header__votes_Text mb-0">
                          Votes remaining today
                        </p>
                      </div>
                    )}
                    {token && (

                      <NavLink
                        to="/liked-videos"
                        className={({ isActive }) =>
                          `nav-link ${isActive ? 'border-bottom-bt-dark' : ''}`
                        }
                        onClick={() => handleNavLinkClick('#liked-videos')}
                      >
                        My Votes
                      </NavLink>
                    )}
                  </div>
                )}
                <div className='DivisoryLine' />

                {token ? (
                  <div className='w-100 d-flex justify-content-center justify-content-xl-end'>
                    <Button
                      variant="rs-secondary"
                      onClick={openShowLogout}
                      className='p-2'
                    >
                      <div className='d-flex justify-content-center align-items-center' style={{ minWidth: 'max-content' }}>
                        <img src={logoutIcon} alt="Facebook icon" />
                        <span className="ms-2">Log out</span>
                      </div>
                    </Button>
                  </div>
                ) : (competitionInfo && competitionInfo.state !== 'finished') ? (
                  <div className='d-flex w-100 gap-2 gap-xl-3 flex-column flex-xl-row'>
                    <Button
                      id="Nav-Bar-Create-Account-Btn"
                      variant="rs-secondary"
                      onClick={() => {
                        handleNavLinkRedirect('/login')
                      }}
                      style={{ minWidth: '83px', height: 'max-content', padding: '8px 12px', margin: '0 auto' }}
                      className='w-75'
                    >
                      Log in
                    </Button>
                    <Button
                      id="Nav-Bar-Create-Account-Btn"
                      variant="rs"
                      onClick={() => {
                        handleNavLinkRedirect('/register')
                      }} style={{ minWidth: '83px', height: 'max-content', padding: '8px 12px', margin: '0 auto', whiteSpace: 'nowrap' }}
                      className='w-75'
                    >
                      Sign up
                    </Button>
                  </div>
                ) : <></>}
              </section>
            </Navbar.Collapse>
          </Container>

        </Navbar>
      </div>

      <div
        className={`Header__Background ${ShowCollapse ? 'Active' : ''}`}
        onClick={toggleShowCollapse}
      />

      <Modal
        centered
        // size="lg"
        size="md"
        // dialogClassName="modal-60w"
        show={showLogout}
        onHide={closeShowLogout}
      >
        <Modal.Body className="p-4">
          <h3 className="text-center">Log out?</h3>
          <p className="mt-5 text-center">
            Your additional daily votes can only be used when you are logged in
            to your account
          </p>
          <div className="mt-5 d-flex gap-3 justify-content-around">
            <Button variant="rs-border w-100" onClick={closeShowLogout}>
              Cancel
            </Button>
            <Button variant="rs w-100" onClick={handleLogout}>
              Log out
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Header

Header.propTypes = {
  isAuth: PropTypes.bool,
}
