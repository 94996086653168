import { useState, useContext, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX, faShareFromSquare } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../context'
import linkDark from '../assets/icons/link-dark.svg'
import facebookIcon from '../assets/icons/facebook.svg'
import twitterIcon from '../assets/icons/twitter.svg'
import whatsappIcon from '../assets/icons/whatsapp.svg'
import PropTypes from 'prop-types'

const ShareVideoModal = ({ show, onClose }) => {
  const { showVideoModal } = useContext(AppContext)

  const [showSuccessClipboard, setShowSuccessClipboard] = useState(false)

  const videoURL = window.location.href

  const socialMediaList = [
    {
      icon: facebookIcon,
      name: 'Facebook',
      link: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(videoURL)}`,
    },
    {
      icon: twitterIcon,
      name: 'Twitter',
      link: `https://twitter.com/intent/tweet?url=${encodeURIComponent(videoURL)}`,
    },
    {
      icon: whatsappIcon,
      name: 'Whatsapp',
      link: `https://api.whatsapp.com/send?text=${encodeURIComponent(videoURL)}`,
    },
  ]

  const copyToClipboard = () => {
    navigator.clipboard.writeText(videoURL).then(() => {
      setShowSuccessClipboard(true)
      setTimeout(() => setShowSuccessClipboard(false), 2000)
    })
  }

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3 className="mb-4">Share video</h3>
          <FontAwesomeIcon icon={faX} size="lg" className="cursor-pointer" onClick={onClose} />
        </div>
        <div className="d-flex justify-content-around">
          <div className="d-flex flex-column align-items-center gap-2 cursor-pointer" onClick={copyToClipboard}>
            <div className="Share__Item_Icon">
              <img src={linkDark} width={25} height={25} alt="link icon" />
            </div>
            <p className="mb-0 text-5 fw-500 text-nowrap">Copy link</p>
          </div>
          {socialMediaList.map((socialMedia, index) => (
            <a
              target="_blank"
              rel="noreferrer"
              href={socialMedia.link}
              className="d-flex flex-column align-items-center gap-2 cursor-pointer"
              key={index}
            >
              <div className="Share__Item_Icon">
                <img src={socialMedia.icon} width={25} height={25} alt="link icon" />
              </div>
              <p className="mb-0 text-5 fw-500 text-nowrap">{socialMedia.name}</p>
            </a>
          ))}
        </div>
        {showSuccessClipboard && (
          <div className="clipboard">
            <p className="mb-0 text-4 fw-500">Link copied to clipboard</p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

ShareVideoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ShareVideoModal