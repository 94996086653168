import PropTypes from 'prop-types'
import { useEffect } from 'react'

const TikTokEmbed = ({ videoSrc, className }) => {


  useEffect(() => {
    async function loadTikTok() {
      if (typeof document !== 'undefined' && !document.getElementById('tiktokScript')) {
        const tiktokScript = document.createElement('script')
        tiktokScript.setAttribute('src', 'https://www.tiktok.com/embed.js')
        tiktokScript.setAttribute('id', 'tiktokScript')
        tiktokScript.setAttribute('async', '')
        document.head.appendChild(tiktokScript)
      }
    }
    loadTikTok()
  }, [videoSrc])


  return (
    <div className={`VideoDetailPage__VideoContainer--TikTok ${className}`}>
      {videoSrc ? (
        <blockquote
          className="tiktok-embed"
          cite={videoSrc}
          data-video-id={videoSrc.split('/').pop()}
          style={{
            width: '100%',
            height: '100%',
            border: 0,
            margin: 0,
            background: '#FFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <a href={videoSrc}> </a>
        </blockquote>
      ) : (
        <p>Invalid TikTok URL</p>
      )}
    </div>
  )
}

TikTokEmbed.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default TikTokEmbed
