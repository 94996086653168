import { Helmet } from 'react-helmet'
import YoutubeEmbed from '../components/YoutubeEmbed'
import { Button, Spinner } from 'react-bootstrap'
import ContestantsVideo from '../components/ContestantsVideo'
import IHeart from '../assets/icons/IHeart.svg'
import IHeartFull from '../assets/icons/IHeartFull.svg'
import IShareIcon from '../assets/icons/shareNewIcon.svg'
import { useEffect, useState, useContext, useMemo } from 'react'
import { AppContext } from '../context'
import InstagramEmbedV2 from '../components/InstagramEmbedV2'
import TikTokEmbed from '../components/TikTokEmbed'
import { getVideos, getVideosDetailsNew, voteVideo, unvoteVideo } from '../api/videos'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import VoteVideoModal from '../components/VoteVideoModal'
import ShareVideoModal from '../components/ShareVideoModal'
import AllVotesUsedModal from '../components/AllVotesUsedModal'

const VideoDetailPage = () => {
  const {
    token,
    unregisteredUserId,
    setUnregisteredUserId,
    refreshRemainingVotes,
    remainingVotes,
    competitionInfo,
  } = useContext(AppContext)
  const { slug } = useParams()
  const navigate = useNavigate()
  
  const [videoData, setVideoData] = useState(null)
  const [isVoted, setIsVoted] = useState(false)
  const [amountLikes, setAmountLikes] = useState(0)
  const [disableLike, setDisableLike] = useState(false)
  const [showVoteModal, setShowVoteModal] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showAllVotesUsedModal, setShowAllVotesUsedModal] = useState(false)

  const { isFetching, data: RecommendedVideos, refetch } = useQuery(
    ['videos', unregisteredUserId],
    () =>
      getVideos({
        page: 1,
        per_page: 100,
        all: 'true',
        recommended: 'recommended',
        unregistered_user_id: !token ? unregisteredUserId : '',
      }),
    {
      initialData: { total_pages: 1, actual_page: 1, data: [] },
    }
  )
  useEffect(() => {
    const getVideoInfo = async () => {
      const { ok, data } = await getVideosDetailsNew(
        token,
        slug,
        !token ? { unregistered_user_id: unregisteredUserId } : {}
      )
      if (ok) {
        setVideoData(data)
      }
    }
    getVideoInfo()
  }, [token, slug, remainingVotes, unregisteredUserId])

  useEffect(() => {
    if (videoData) {
      setIsVoted(videoData.user_votes_today !== 0)
      setAmountLikes(videoData.user_votes_today)
    }
  }, [videoData, token, slug])

  const filteredRecommendedVideos = useMemo(() => {
    return RecommendedVideos?.data.filter(video => video.slug !== slug)
  }, [RecommendedVideos.data, slug])

  const handleVoteClick = () => {
    if (isVoted) {
      setShowVoteModal(true)
    } else if (remainingVotes > 0) {
      setShowVoteModal(true)
    } else {
      setShowAllVotesUsedModal(true)
    }
  }

  const handleShareClick = () => {
    setShowShareModal(true)
  }

  const handleVoteSubmit = async (votes, isRemoveVote) => {
    if (disableLike) return

    setDisableLike(true)
    let response
    const body = { votes_amount: votes, unregistered_user_id: unregisteredUserId }

    if (token) {
      if (votes === 0) {
        response = await voteVideo(videoData.id, token, { votes_amount: 0 })
      } else {
        response = await voteVideo(videoData.id, token, body)
      }
    } else {
      if (videoData.user_votes_today === 1) {
        response = await unvoteVideo(videoData.id, null, { unregistered_user_id: unregisteredUserId })
        if (response.ok) localStorage.removeItem('votedVideoSlug')
        refreshRemainingVotes()
      } else {
        response = await voteVideo(videoData.id, null, body)
        refreshRemainingVotes()
      }
    }

    if (response?.ok) {
      const { data } = response
      refreshRemainingVotes()
      if (!token) {
        setUnregisteredUserId(data.unregistered_user_id || unregisteredUserId)
      }
      const updatedVideoData = await getVideosDetailsNew(
        token,
        slug,
        !token ? { unregistered_user_id: unregisteredUserId } : {}
      )
      setVideoData(updatedVideoData.data)
      setAmountLikes(updatedVideoData.data.user_votes_today)
      setIsVoted(updatedVideoData.data.user_votes_today > 0)
    }

    setDisableLike(false)
    setShowVoteModal(false)
    if (!isRemoveVote) {
      setTimeout(() => {
        setShowAllVotesUsedModal(true)
      }, 300)
    }
  }
  const handleVideo = () => {
    switch (videoData?.hosted_in) {
    case 'Youtube':
      return <YoutubeEmbed video={videoData?.video_link} className="VideoDetailPage__Video" />
    case 'TikTok':
      return <TikTokEmbed videoSrc={videoData?.video_link} className="VideoDetailPage__Video" />
    case 'Instagram':
      return <InstagramEmbedV2 videoSrc={videoData?.video_link} className="VideoDetailPage__Video" />
    default:
      return <></>
    }
  }
  if (!videoData) {
    return (
      <div className="VideoDetailPage w-100 h-100 d-flex justify-content-center align-items-center text-center" style={{ marginBottom: '49.25px' }}>
        <Spinner animation="border" variant="warning" />
      </div>
    )
  }

  return (
    <main className="VideoDetailPage">
      <Helmet>
        <title>{videoData.name} | Outback Explorer Competiton</title>
      </Helmet>
      <section className="VideoDetailPage__Main">
        <div className="VideoDetailPage__VideoContainer">
          {handleVideo()}
        </div>
        <div className="VideoDetailPage__Content">
          <div className="VideoDetailPage__Content__Container">
            <p className="text-1 text-rs-black fw-600 order-2 order-lg-1">{videoData?.name}</p>
            <div className="d-flex gap-3 order-1 order-lg-2">
              <Button variant="rs-border" className={`d-flex gap-2 w-100 w-sm-auto votes ${competitionInfo && competitionInfo.state !== 'ongoing' ? 'btn-maxWidth' : ''}`} onClick={handleShareClick}>
                <img src={IShareIcon} alt="Vote icon" />
                Share
              </Button>
              {competitionInfo && competitionInfo.state === 'ongoing' && (
                <Button variant="rs" className="d-flex gap-2 votes w-100 w-sm-auto" onClick={handleVoteClick} >
                  <>
                    <img src={isVoted ? IHeartFull : IHeart} alt="Vote icon" />
                    {amountLikes > 1 && amountLikes} {isVoted ? (amountLikes > 1 ? 'Votes' : 'Voted') : 'Vote'}
                  </>
                </Button>
              )}
            </div>
          </div>
          <p className="fw-600 text-4">
            {videoData?.user_name} {videoData?.user_last_name}
          </p>
          <p className="fw-400 ffam-FreeSans text-4">{videoData?.description}</p>
        </div>
      </section>
      {showVoteModal && (
        <VoteVideoModal
          show={showVoteModal}
          onClose={() => setShowVoteModal(false)}
          videoId={videoData.id}
          initialVotes={amountLikes}
          onSubmit={handleVoteSubmit}
          token={token}
          isVoted={isVoted}
        />
      )}
      {showAllVotesUsedModal && (
        <AllVotesUsedModal
          show={showAllVotesUsedModal}
          onClose={() => setShowAllVotesUsedModal(false)}
        />
      )}
      {showShareModal && (
        <ShareVideoModal
          show={showShareModal}
          onClose={() => setShowShareModal(false)}
          videoId={videoData.id}
        />
      )}
      <section className="VideoDetailPage__SideVideos">
        <p className="fw-600 text-2" style={{ padding: '16px' }}>
          Watch other contestant’s videos!
        </p>
        {filteredRecommendedVideos.map((video) => (
          <div
            className="VideoDetailPage__SideVideos__Container"
            key={video.id}
            onClick={() => {
              navigate(`/${video.competition_name}/${video.slug}`)
            }}
          >
            <ContestantsVideo
              image={video.thumbnail}
              username={`${video.user_name} ${video.user_last_name}`}
              title={video.name}
              description={video.description}
            />
          </div>
        ))}
      </section>
    </main>
  )
}

export default VideoDetailPage