import { Card, Col, Stack } from 'react-bootstrap'
import * as constants from '../constants'
import playIcon from '../assets/landing/playIcon.png'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { AppContext } from '../context'

const AdminAcceptedVideoCard = (props) => {
  const { openVideoModal } = useContext(AppContext)

  return (
    <Col key={props.id} xl={3} md={4} sm={12} className="mb-4">
      <div className='d-flex w-100 flex-column'>
        <Card className='video-card crop h-100'>
          <div style={{ position: 'absolute', zIndex: 100, right: 0 }}>
            <Dropdown>
              <Dropdown.Toggle style={{ backgroundColor: 'rgba(52, 52, 52, 0.8)' }} id={props.id}>
                <FontAwesomeIcon
                  icon={faEdit}
                  size="lg"
                  className="me-2"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => { props.pendingVideo(props.id) }}>Mark for review</Dropdown.Item>
                {props.staffPick ?
                  <Dropdown.Item onClick={() => { props.staffPickVideo(props.id, false) }}>Remove Staff Pick</Dropdown.Item>
                  :
                  <Dropdown.Item onClick={() => { props.staffPickVideo(props.id, true) }}>Staff Pick</Dropdown.Item>
                }
                {props.recommended ?
                  <Dropdown.Item onClick={() => { props.removeRecommended(props.id, false) }}>Remove Recommended</Dropdown.Item>
                  :
                  <Dropdown.Item onClick={() => { props.removeRecommended(props.id, true) }}>Recommended</Dropdown.Item>
                }
                <Dropdown.Item onClick={() => { props.rejectVideoModal(props.id) }}>Reject</Dropdown.Item>
                {props.bannedLeaderboard ?
                  <Dropdown.Item onClick={() => { props.bannedLeaderboardVideo(props.id, false) }}>Approve for leaderbaord</Dropdown.Item>
                  :
                  <Dropdown.Item onClick={() => { props.bannedLeaderboardVideo(props.id, true) }}>Remove from leaderboard</Dropdown.Item>
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Card.Img src={`${props.image.indexOf('http://') === 0 || props.image.indexOf('https://') === 0 ? '' : constants.URL}${props.image}`} alt="Card image" />
          <Card.ImgOverlay className="text-white h-100 d-flex flex-column justify-content-end">
            <div className='Card_Info'>
              {props.staffPick &&
                <div className="Card_Info_Item">
                  <p>Staff pick</p>
                </div>
              }
              {props.recommended &&
                <div className="Card_Info_Item">
                  <p>Recommended</p>
                </div>
              }
            </div>
            <img src={playIcon} className="play-icon cursor-pointer" alt="" onClick={() => openVideoModal({ ...props.video, isAdmin: true })} />
            <div>
              <Stack direction="horizontal" gap={4} className="justify-content-center">
                <div>
                </div>
              </Stack>
            </div>
          </Card.ImgOverlay>
        </Card>
        <div className="mt-2">
          <p className='Card__Name'>{props.videoName}</p>
          <p className='Card__UserName'>
            {props.video?.user_name} {props.video?.user_last_name}
          </p>
        </div>
      </div>
    </Col>
  )
}

export default AdminAcceptedVideoCard

AdminAcceptedVideoCard.propTypes = {
  video: PropTypes.object,
  id: PropTypes.number.isRequired,
  socialNet: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired,
  videoName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string,
  country: PropTypes.string,
  city: PropTypes.string,
  age: PropTypes.string,
  mobile: PropTypes.string,
  image: PropTypes.string,
  staffPick: PropTypes.bool,
  recommended: PropTypes.bool,
  description: PropTypes.string.isRequired,
  videoSrc: PropTypes.string.isRequired,
  voted: PropTypes.bool,
  show: PropTypes.bool,
  pendingVideo: PropTypes.func,
  rejectVideoModal: PropTypes.func,
  staffPickVideo: PropTypes.func,
  removeRecommended: PropTypes.func,
  isAdmin: PropTypes.bool,
  bannedLeaderboard: PropTypes.bool,
  bannedLeaderboardVideo: PropTypes.func,
}
