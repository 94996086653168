import { Col, Container, Row } from 'react-bootstrap'
import { useContext } from 'react'
import { AppContext } from '../context'
import { URL as URL_BACK } from '../constants'

const Prizes = () => {
  const {
    timelineCompetitions,
  } = useContext(AppContext)

  const { currentCompetition, lastCompetitions, nextCompetition } = timelineCompetitions
  return (
    <>
      <section className="d-flex WinPrices" id="prizes">
        <Container className="my-auto">
          <h2 className="text-white LandingTitle mb-5 text-center">
            Win amazing prizes!
          </h2>
          {currentCompetition && !nextCompetition && (
            <>
              {currentCompetition.is_active_selected_winner && (
                <>
                  <Row className="mb-5">
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2}
                      className="my-auto text-center px-4 d-flex align-items-center justify-content-center"
                    >
                      <img src={`${currentCompetition.selected_icon?.startsWith('http') ? '' : URL_BACK}${currentCompetition.selected_icon}`} className="img-fluid mb-5 mt-2" style={{ maxHeight: '170px' }} alt="" />
                    </Col>
                    <Col className="Prizes-Custom px-5">
                      <p className="text-rs-yellow price-title">{currentCompetition.selected_winner_title}</p>
                      <div className="Prizes-Custom" dangerouslySetInnerHTML={{ __html: currentCompetition.selected_winner_subtitle }} />

                    </Col>
                  </Row>
                </>
              )}
              {currentCompetition.is_active_leaderboard_winners && (
                <>
                  <Row className="mb-5">
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2}
                      className="my-auto text-center px-4 "
                    >
                      <img src={`${currentCompetition.ranking_icon?.startsWith('http') ? '' : URL_BACK}${currentCompetition.ranking_icon}`} className="img-fluid mb-5 mt-2" style={{ maxHeight: '170px' }} alt="" />
                    </Col>
                    <Col className="px-5 Prizes-Custom">
                      <p className="text-rs-yellow price-title">{currentCompetition.leaderboard_winners_title}</p>
                      <div className="Prizes-Custom" dangerouslySetInnerHTML={{ __html: currentCompetition.leaderboard_winners_subtitle }} />
                    </Col>
                  </Row>
                </>
              )}
              {currentCompetition.is_active_lucky_winner_home && (
                <>
                  <Row>
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2}
                      className="my-auto text-center px-4"
                    >
                      <img src={`${currentCompetition.lucky_winner_icon?.startsWith('http') ? '' : URL_BACK}${currentCompetition.lucky_winner_icon}`} className="img-fluid mb-5 mt-2" style={{ maxHeight: '170px' }} alt="" />
                    </Col>
                    <Col className="px-5 Prizes-Custom">
                      <p className="text-rs-yellow price-title">{currentCompetition.lucky_winner_title}</p>
                      <div className="Prizes-Custom" dangerouslySetInnerHTML={{ __html: currentCompetition.lucky_winner_subtitle }} />
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
          {lastCompetitions && lastCompetitions.length !== 0 && !nextCompetition && !currentCompetition && (
            <>
              {lastCompetitions[0].is_active_selected_winner && (
                <>
                  <Row className="mb-5">
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2}
                      className="my-auto text-center px-4"
                    >
                      <img src={`${lastCompetitions[0].selected_icon?.startsWith('http') ? '' : URL_BACK}${lastCompetitions[0].selected_icon}`} className="img-fluid mb-5 mt-2" style={{ maxHeight: '170px' }} alt="" />
                    </Col>
                    <Col className="px-5 Prizes-Custom">
                      <p className="text-rs-yellow price-title">{lastCompetitions[0].selected_winner_title}</p>
                      <div className="Prizes-Custom" dangerouslySetInnerHTML={{ __html: lastCompetitions[0].selected_winner_subtitle }} />
                      {/* <p className="d-inline text-white price-text fw-semibold">{lastCompetitions[0].selected_winner_subtitle}</p> */}
                    </Col>
                  </Row>
                </>
              )}
              {lastCompetitions[0].is_active_leaderboard_winners && (
                <>
                  <Row>
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2}
                      className="my-auto text-center px-4"
                    >
                      <img src={`${lastCompetitions[0].ranking_icon?.startsWith('http') ? '' : URL_BACK}${lastCompetitions[0].ranking_icon}`} className="img-fluid mb-5 mt-2" style={{ maxHeight: '170px' }} alt="" />
                    </Col>
                    <Col className="px-5 Prizes-Custom">
                      <p className="text-rs-yellow price-title">{lastCompetitions[0].leaderboard_winners_title}</p>
                      <div className="Prizes-Custom" dangerouslySetInnerHTML={{ __html: lastCompetitions[0].leaderboard_winners_subtitle }} />

                    </Col>
                  </Row>
                </>
              )}
              {lastCompetitions[0].is_active_lucky_winner_home && (
                <>
                  <Row>
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2}
                      className="my-auto text-center px-4"
                    >
                      <img src={`${lastCompetitions[0].lucky_winner_icon?.startsWith('http') ? '' : URL_BACK}${lastCompetitions[0].lucky_winner_icon}`} className="img-fluid mb-5 mt-2" style={{ maxHeight: '170px' }} alt="" />
                    </Col>
                    <Col className="px-5 Prizes-Custom">
                      <p className="text-rs-yellow price-title">{lastCompetitions[0].lucky_winner_title}</p>
                      <div className="Prizes-Custom" dangerouslySetInnerHTML={{ __html: lastCompetitions[0].lucky_winner_subtitle }} />

                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
          {nextCompetition && (
            <>
              {nextCompetition.is_active_selected_winner && (
                <>
                  <Row className="mb-5">
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2}
                      className="my-auto text-center px-4"
                    >
                      <img src={`${nextCompetition.selected_icon?.startsWith('http') ? '' : URL_BACK}${nextCompetition.selected_icon}`} className="img-fluid mb-5 mt-2" style={{ maxHeight: '170px' }} alt="" />
                    </Col>
                    <Col className="px-5 Prizes-Custom">
                      <p className="text-rs-yellow price-title">{nextCompetition.selected_winner_title}</p>
                      {/* <p className="d-inline text-white price-text fw-semibold">{nextCompetition.selected_winner_subtitle}</p> */}
                      <div className="Prizes-Custom" dangerouslySetInnerHTML={{ __html: nextCompetition.selected_winner_subtitle }} />

                    </Col>
                  </Row>
                </>
              )}
              {nextCompetition.is_active_leaderboard_winners && (
                <>
                  <Row>
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2}
                      className="my-auto text-center px-4"
                    >
                      <img src={`${nextCompetition.ranking_icon?.startsWith('http') ? '' : URL_BACK}${nextCompetition.ranking_icon}`} className="img-fluid mb-5 mt-2" style={{ maxHeight: '170px' }} alt="" />
                    </Col>
                    <Col className="px-5 Prizes-Custom">
                      <p className="text-rs-yellow price-title">{nextCompetition.leaderboard_winners_title}</p>
                      {/* <p className="d-inline text-white price-text fw-semibold">{nextCompetition.leaderboard_winners_subtitle}</p> */}
                      <div className="Prizes-Custom" dangerouslySetInnerHTML={{ __html: nextCompetition.leaderboard_winners_subtitle }} />

                    </Col>
                  </Row>
                </>
              )}
              {nextCompetition.is_active_lucky_winner_home && (
                <>
                  <Row>
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2}
                      className="my-auto text-center px-4"
                    >
                      <img src={`${nextCompetition.lucky_winner_icon?.startsWith('http') ? '' : URL_BACK}${nextCompetition.lucky_winner_icon}`} className="img-fluid mb-5 mt-2" style={{ maxHeight: '170px' }} alt="" />
                    </Col>
                    <Col className="px-5 Prizes-Custom">
                      <p className="text-rs-yellow price-title">{nextCompetition.lucky_winner_title}</p>
                      {/* <p className="d-inline text-white price-text fw-semibold">{nextCompetition.lucky_winner_subtitle}</p> */}
                      <div className="Prizes-Custom" dangerouslySetInnerHTML={{ __html: nextCompetition.lucky_winner_subtitle }} />

                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Container>
      </section>
    </>
  )
}

Prizes.propTypes = {}

export default Prizes