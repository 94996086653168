import FormCompetition from './FormCompetition'
import infoBackground from '../assets/landing/AddVideoBackground.png'
import outbackLogo from '../assets/landing/outback-logo-blue.svg'
import socialMediaLogos from '../assets/landing/social-media-logos.svg'
import clockWatch from '../assets/landing/clock-watch.svg'
import {DownloadModalContext} from '../pages/Home'
import { useContext } from 'react'

const SubmitVideo = () => {
  const {setShow} = useContext(DownloadModalContext)
  return (
    <section className="HowToEnter my-5" id="enter-competition">
      <div >
        <p className="mb-3 text-Landing fw-600 text-center">Enter competition</p>
        <div className='SubmitVideo'>
          <div className="SubmitVideo_Form my-4">
            <FormCompetition />
          </div>
          <div className="SubmitVideo_Info">
            
            <div className='d-flex justify-content-center align-items-center position-relative h-100'>
              <div className='SubmitVideo_Info_Content'>
                <img src={infoBackground} alt="info background" className='infoBackground' />
                <div className="ImportantCard">
                  <h4 className="SubmitVideo_Info_Text">Video requirements:</h4>
                  <div className='ImportantCard_Icon'>
                    <img
                      src={outbackLogo}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className='ImportantCard_info'>
                    <p className='ImportantCard_Texts'>
                    Must include Outback Explorer Competition start and end frames.
                    </p>
                  </div>
                  <div className='ImportantCard_Icon'>
                    <img
                      src={socialMediaLogos}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className='ImportantCard_info' style={{ width: '90%'}}>
                    <p className='ImportantCard_Texts'>Must be embedded from Instagram, YouTube or TikTok.</p>
                  </div>
                  <div className="ImportantCard_Icon">
                    <img
                      src={clockWatch}
                      className="img-fluid"
                      alt=""
                      style={{color: '#C5351C'}}
                    />
                  </div>
                  <div className='ImportantCard_info'>
                    <p className='ImportantCard_Texts'>Maximum 2 minutes long.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </section>
  )
}

SubmitVideo.propTypes = {}

export default SubmitVideo