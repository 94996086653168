import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { AppContext } from '../context'
import PropTypes from 'prop-types'

const AllVotesUsedModal = ({ show, onClose }) => {
  const { remainingVotes, token } = useContext(AppContext)

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Body>
        <div className="d-flex justify-content-center align-items-center mb-3 position-relative">
          <h3>Thanks for voting!</h3>
          <FontAwesomeIcon icon={faX} size="lg" className="cursor-pointer position-absolute" onClick={onClose} style={{ right: '0', top: '0' }} />
        </div>
        {remainingVotes === 0 && (
          token ? (
            <div className='d-flex flex-column w-100 gap-2 justify-content-center align-items-center my-4'>
              <p className="text-4 text_primary">
                You’ve used all your votes for today.
              </p>
              <p className="text-4 text_primary">
                Don’t forget to come back tomorrow to cast more!
              </p>
            </div>
          ) : (
            <>
              <div className='d-flex flex-column w-100 gap-2 justify-content-center align-items-center my-4'>
                <p className="text-4 text_primary">
                  You’ve reached your voting limit for today, but why stop there?
                </p>
                <p className="text-4 text_primary">
                  Create an account to <strong>unlock 5 daily votes</strong> and enter the draw to win a <strong>Rescue Swag first aid kit</strong>!
                </p>
              </div>
              <div className="d-flex gap-3 mt-auto">
                <Link to="/login" className="w-100">
                  <div className="w-100  btn btn-rs-border" onClick={onClose} style={{ minWidth: 'max-content' }}>
                    Login
                  </div>
                </Link>
                <Link to="/register" className="w-100">
                  <div className="w-100 btn btn-rs" onClick={onClose} style={{ minWidth: 'max-content' }}>
                    Create account
                  </div>
                </Link>
              </div>
            </>
          )
        )}
        {remainingVotes !== 0 && token && (
          <div className='d-flex flex-column w-100 gap-2 justify-content-center align-items-center my-4'>
            <p className="text-4 text_primary">
              You still have votes available to cast today!
            </p>
            <p className="text-4 text_primary">
              Keep voting to support your favourite videos.
            </p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

AllVotesUsedModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default AllVotesUsedModal
