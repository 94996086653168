import { useContext, useEffect } from 'react'
import { useLocation, Outlet, useNavigate } from 'react-router'
import AuthenticatedHeader from '../containers/AuthenticatedHeader'
import AdminSideBar from '../containers/AdminSideBar'
import { AppContext } from '../context'
import VideoModal from '../components/VideoModal'

const AdminLayout = () => {
  const { token, firstCharge, user, setPreviousPath } =
    useContext(AppContext)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!firstCharge && !token) {
      navigate('/login')
    }
    if (!firstCharge && user && !user.user_serializer.is_superuser)
      navigate('/')
  }, [token, firstCharge, user])

  useEffect(() => {
    document.body.scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    return () => {
      setPreviousPath(location.pathname)
    }
  }, [location])

  return (
    <>
      <AuthenticatedHeader />
      <AdminSideBar />
      <div id="content">
        <Outlet />
      </div>
      <VideoModal />
    </>
  )
}

export default AdminLayout
