import { Col, Row, Container, InputGroup, FormControl } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
// import AuthImg from '../../assets/auth/authimg.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { login, resendEmailConfirmation } from '../../api/users'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { AppContext } from '../../context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { Helmet } from 'react-helmet'

const Login = () => {
  const { setToken, userLog, setUserLog, competitionInfo, previousPath } = useContext(AppContext)
  const [generalError, setGeneralError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showReSend, setShowReSend] = useState(false)
  const [disableReSendMail, setDisableReSendMail] = useState(false)
  const navigate = useNavigate()

  const handlerShowPassword = () => {
    setShowPassword((prev) => !prev)
  }

  const formik = useFormik({
    initialValues: {
      email: userLog.email,
      password: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .required('E-mail is required.')
        .max(60, 'Maximum characters: 60.')
        .email('Enter a valid email address.'),
      password: Yup.string()
        .required('Password is required.')
        .min(8, 'Minimum characters: 8.')
        .max(40, 'Maximum characters: 40.'),
    }),
    onSubmit: async ({ email, password }) => {
      if (email.includes(' ')) {
        email = email.replace(/ /g, '')
      }

      setUserLog((prev) => ({
        ...prev,
        email,
      }))

      try {
        const response = await login({ email, password })
        if (response.key) {
          setToken(response.key)
          navigate(previousPath || '/')
          setGeneralError('')
        } else if (response.data) {
          if (response.data.non_field_errors) {
            if (response.data.non_field_errors[0] === 'Unable to log in with provided credentials.') {
              setGeneralError('Incorrect email or password.')
            } else if (response.data.non_field_errors[0] === 'E-mail is not verified.') {
              setGeneralError('E-mail is not verified.')
              setShowReSend(true)
            } else {
              console.error(response.data.non_field_errors)
            }
          } else if (response.status === 400) {
            const errors = response.data
            let errorMessages = []
            if (errors.email) {
              errorMessages = errorMessages.concat(errors.email)
            }
            if (errors.password) {
              errorMessages = errorMessages.concat(errors.password)
            }
            if (errorMessages.length > 0) {
              setGeneralError(errorMessages.join(' '))
            }
          }
        }
      } catch (error) {
        console.error('Login failed:', error)
        setGeneralError('An unexpected error occurred. Please try again.')
      }
    },
  })

  const reSendMail = async () => {
    setDisableReSendMail(true)
    const response = await resendEmailConfirmation({
      email: formik.values.email,
    })

    if (response.error) console.error(response.error)
    else if (response) {
      console.log('mail forwarded')
    }
    setDisableReSendMail(false)
  }

  useEffect(() => {
    setShowReSend(false)
  }, [formik.values.email])

  return (
    <>
      <Helmet>
        <title>Log in for more votes | Outback Explorer Competition</title>
      </Helmet>
      <div className="AuthContainer">
        <Container fluid>
          <Row>
            <Col
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
              xxl={{ span: 4, offset: 4 }}
              className="mt-5"
            >
              <div className="bg-white AuthBox mb-4" style={{ maxWidth: '500px', margin: '0 auto' }}>
                <Container fluid>
                  <Row >
                    <Col className="p-4">
                      <div className="text-center mb-2">
                        <p className="mb-4 text-1 fw-600">Log in</p>
                        {competitionInfo && competitionInfo.state === 'ongoing' && (
                          <p className='text-4 ffam-FreeSans mb-4'>
                            Log into your account to <span className='fw-700'>activate your 5 daily votes!</span>
                          </p>)}
                      </div>
                      <Form className="mb-4" onSubmit={formik.handleSubmit}>
                        <Form.Group
                          className="position-relative mb-4 fw-bold"
                          controlId="email"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            isInvalid={!!formik.errors.email}
                            autoComplete="email"
                          />
                          <Form.Control.Feedback
                            className="text-red ffam-FreeSans fw-400 text-5"
                            type="invalid"
                          >
                            <span className='feedback'>
                              {formik.errors.email}
                            </span>
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className="position-relative mb-4 fw-bold"
                          controlId="password"
                        >
                          <Form.Label>Password</Form.Label>
                          <InputGroup
                            className="position-relative"
                          >
                            <FormControl
                              type={showPassword ? 'text' : 'password'}
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              isInvalid={!!formik.errors.password}
                              autoComplete="current-password"
                              style={{ borderRadius: '6px' }}
                            />
                            <div
                              className="btn-outline-secondary d-flex align-items-center position-absolute end-0 top-0 h-100 translate-middle-x z-index-1"
                            >
                              {showPassword ? (
                                <FontAwesomeIcon icon={faEyeSlash} onClick={handlerShowPassword} />
                              ) : (
                                <FontAwesomeIcon icon={faEye} onClick={handlerShowPassword} />
                              )}
                            </div>
                            <Form.Control.Feedback
                              className="text-red ffam-FreeSans fw-400 text-5"
                              type="invalid"
                            >
                              {formik.errors.password}
                            </Form.Control.Feedback>
                          </InputGroup>
                          <Form.Text>
                            <Link to="/forgot-password" className='ffam-FreeSans fw-400 text-black-primary text-5 d-flex w-100 justify-content-center gap-1 p-2' style={{ height: 'max-content' }}>
                              <p className='m-0'>Forgot your password?</p>
                              <span className='fw-700' style={{ textDecoration: 'underline' }}> Reset here</span>
                            </Link>
                          </Form.Text>
                          {generalError.length !== 0 && (

                            <Form.Text>
                              <div className='d-flex w-100 my-2'>
                                <p className="text-red ffam-FreeSans fw-400 text-5">{generalError}</p>
                              </div>
                            </Form.Text>
                          )}
                        </Form.Group>

                        {showReSend ? (
                          <button
                            className="mb-3 text-decoration-underline"
                            disabled={disableReSendMail}
                            onClick={reSendMail}
                          >
                            {disableReSendMail
                              ? 'sending...'
                              : 'I did not receive verification email. Re-send'}
                          </button>
                        ) : (
                          <></>
                        )}

                        <div className="d-flex w-100">
                          <Button variant="rs" type="submit" className='w-100 login fw-400'>
                            Log in
                          </Button>
                        </div>
                      </Form>
                      <div className='d-flex flex-column w-100 gap-4'>
                        <div className='d-flex w-100 justify-content-center'>
                          <p className='ffam-FreeSans fw-400 text-black-primary text-5'>Don&apos;t have an account, sign up below</p>
                        </div>
                        <Link to="/register" className="d-grid gap-2 mt-auto">
                          <Button variant="rs-border" className='login fw-400'>Sign up</Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Login