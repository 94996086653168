import { useContext } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Modal, Button } from 'react-bootstrap'
import {
  faInstagram,
  faTiktok,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { DownloadModalContext } from '../pages/Home'
// import YoutubeFrame from '../assets/videoFrames/outback-explorer-youtube-frames.mp4'
// import PortraitFrame from '../assets/videoFrames/outback-explorer-portrait-frames.mp4'
import { AppContext } from '../context'
import { URL as API_URL } from '../constants'
export default function DownloadModal() {
  const { show, setShow } = useContext(DownloadModalContext)
  const { competitionInfo } = useContext(AppContext)
  const handleClose = () => setShow(false)

  const handleDownload = (videoPath) => {
    const link = document.createElement('a')
    link.href = videoPath
    link.download = videoPath.split('/').pop()
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Download video frames</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your video must include the{' '}
          <strong>Outback Explorer Competition</strong> video frames.
        </Modal.Body>
        <Modal.Body>
          <span style={{ fontWeight: 'bold' }}>Select Platform:</span>
        </Modal.Body>
        <div className="d-flex flex-wrap gap-3 px-2 mb-4 ms-2">
          {competitionInfo && competitionInfo.state !== 'finished' && (
            <>
              <Button
                variant=""
                onClick={() => handleDownload('/videos/Outback_Frames_Tiktok-Instagram.mp4')}
                style={{
                  backgroundColor: 'black',
                  borderWidth: 0,
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                <span style={{ marginRight: 10 }}>TikTok</span>
                <FontAwesomeIcon icon={faTiktok} className="me-1" />
              </Button>
              <Button
                variant=""
                onClick={() => handleDownload('/videos/Outback_Explorer_Frames_-_Youtube.mp4')}
                style={{
                  backgroundColor: 'red',
                  borderWidth: 0,
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                <span style={{ marginRight: 10 }}>YouTube</span>
                <FontAwesomeIcon icon={faYoutube} className="me-1" />
              </Button>
              <Button
                variant=""
                onClick={() => handleDownload('/videos/Outback_Frames_Tiktok-Instagram.mp4')}
                style={{
                  backgroundColor: '#E1306C',
                  borderWidth: 0,
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                <span style={{ marginRight: 10 }}>Instagram</span>
                <FontAwesomeIcon icon={faInstagram} className="me-1" />
              </Button>
            </>
          )}
        </div>
        <Modal.Footer>
          <Button variant="" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  )
}

DownloadModal.propTypes = {}
